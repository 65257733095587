import apisauce from 'apisauce';

export default () => {
    const api = apisauce.create({
        baseURL: `${process.env.REACT_APP_API}/auth`,
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'json'
    });

    const login = (username, password) => api.post('login', {
        username, password
    });

    return {
        login
    };
};
