import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from '@fortawesome/free-solid-svg-icons';

const StyledCloseButton = styled.button`
    width: 20px;
    height: 20px;
    border: 2px solid #1A1919;
    border-radius: 8px;
    background: white;
    padding-top: 0px;
    padding-left: 3.5px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    padding-top: 1px;
`;

function CloseButton(props) {

    return (
        <StyledCloseButton {...props}>
            <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
        </StyledCloseButton>
    )
}

export default CloseButton;