import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../SingleComponents/PrimaryButton/PrimaryButton';
import ThirdButton from '../../SingleComponents/ThirdButton/ThirdButton';
import Text from '../../SingleComponents/Text/text';
import UpdateCompanyPage from '../../../Pages/UpdateCompanyPage/updatecompanypage';

const StyledCompanyList = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 13px 15px 13px 50px;
    border: solid 1px ${props => props.theme.color.borderGray};
    border-radius: 5px 5px 0px 0px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' width='13' height='13' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    background-position: left 20px top 50%;
    box-sizing: border-box;

    :focus {
        outline: none !important;
        border:1px solid ${(props) => props.theme.color.secondary};
    }
`;

const CompaniesTable = styled.div`
    overflow-y: auto;
    height: 100%;

    div:nth-child(2n+1) {
        background: #FAFAFB 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:nth-child(2n) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:last-child {
        border: 1px solid #DFDFDF;
    }
`;

const CompanyItem = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    cursor: pointer;
    user-select: none;

    padding: 10px 15px;
    box-sizing: border-box;

    ${({ selected }) => selected === true && `
        background: #DFDFDF 0% 0% no-repeat padding-box !important;
    `}

    @media (max-width: 820px) {
        flex-direction: column;
        -moz-box-align: start;
        align-items: start;
      }
`;

function CompanyList(props) {

    const { user, companies, onDeletedCompany, onUpdatedCompany, onViewCompanyProperties, ...restProps } = props;

    const [searchText, setSearchText] = useState('');
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(-1);
    const [isCompanyEditMode, setIsCompanyEditMode] = useState(false);
    const [filteredCompanies, setFilteredCompanies] = useState([]);

    const onViewCompanyDetails = () => {
        setIsCompanyEditMode(true);
    }

    useEffect(() => {
        setFilteredCompanies(companies.filter((c) => {
            return c.CompanyName.toLowerCase().includes(searchText);
        }));
    }, [searchText, companies]);

    return <StyledCompanyList {...restProps}>
        <SearchInput placeholder='Search for a company' onChange={(e) => setSearchText(e.target.value)}/>
        <CompaniesTable>
            {companies.filter((c) => {
                return c.CompanyName.toLowerCase().includes(searchText);
            }).map((c, idx) => {
                return <CompanyItem key={idx} selected={selectedCompanyIndex===idx} onClick={(e) => setSelectedCompanyIndex(idx)}>
                    <Text style={{fontSize: 13, fontWeight: selectedCompanyIndex===idx ? 600 : 400}}>{c.CompanyName}</Text>
                    {(user.Type===true && selectedCompanyIndex===idx) && <ThirdButton caption='Edit company' onClick={(e) => onViewCompanyDetails(idx)} style={{marginLeft: 'auto', fontSize: 11}}></ThirdButton>}
                    {(selectedCompanyIndex===idx) && <PrimaryButton caption='View properties' onClick={(e) => onViewCompanyProperties(filteredCompanies[selectedCompanyIndex])} style={{marginLeft: user.Type ? 0 : 'auto', fontSize: 11}}></PrimaryButton>}
                </CompanyItem>
            })}
        </CompaniesTable>
        {isCompanyEditMode && <UpdateCompanyPage company={filteredCompanies[selectedCompanyIndex]} onClose={() => setIsCompanyEditMode(false)} onUpdatedCompany={onUpdatedCompany} onDeletedCompany={onDeletedCompany}/>}
    </StyledCompanyList>
}

export default CompanyList;