import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defaultTheme } from './theme/theme';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from './Pages/Login/login';
import Homepage from './Pages/Homepage/homepage';
import MainActions, { MainSelectors } from './redux/MainRedux';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

function App(props) {
  const { isLoggedin } = props;
	
	useEffect(() => {
		if (isLoggedin) {
			if (!Cookies.get('transthermal-token')) {
				props.setLogout();
			}
		}
	});

	return (
		<ThemeProvider theme={defaultTheme}>
			<Router>
				<Routes>
					{!isLoggedin && (<Route path='*' element={<Navigate to='/login' />} />)}
					
					<Route path='/login' element={isLoggedin ? <Navigate to='/companies' /> : <LoginPage {...props}/>}/>
					
					<Route path='*' element={<Homepage {...props}/>}/>
				</Routes>
			</Router>
    	</ThemeProvider>
	);
}

App.propTypes = {
	history: PropTypes.object,
	isLoggedin: PropTypes.bool,
	user: PropTypes.object,
	setLogin: PropTypes.func,
	setLogout: PropTypes.func,
};

const mapStatesToProps = state => ({
	isLoggedin: MainSelectors.selectLoggedIn(state),
	user: MainSelectors.selectUser(state),
});

const mapDispatchToProps = dispatch => ({
	setLogin: (user) => dispatch(MainActions.setLogin(user)),
	setLogout: () => dispatch(MainActions.setLogout()),
});

export default connect(
	mapStatesToProps,
	mapDispatchToProps
)(App);
