import styled from 'styled-components';
import CloseButton from '../../components/SingleComponents/CloseButton/closebutton';

const StyledFullScreenImage = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const StyledImage = styled.div`
    position: relative;
    display: flex;
    width: 640px;
    height: 480px;
    box-shadow: 3px 3px 10px 0px;
    margin: auto;
`;

const CloseButton1 = styled.div`
    position: absolute;
    width: 18px;
    height: 18px;
    bottom: 10px;
    right: 10px;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 0px;
    marginTop: auto;
`;

function FullScreenImage(props) {

    const { url, onClose, ...restProps } = props;

    return <StyledFullScreenImage {...restProps} onClick={onClose}>
        <StyledImage onClick={(e) => e.stopPropagation()}>
            <img src={url} style={{width: '100%', height: '100%', borderRadius: 5}} />
            <CloseButton onClick={onClose} style={{position: 'absolute', right: 20, top: 20, color: 'white', background: 'transparent', borderColor: 'white'}}/>
        </StyledImage>
    </StyledFullScreenImage>
}

export default FullScreenImage;