import styled from 'styled-components';
import Text from '../../SingleComponents/Text/text';
import PasswordInput from '../../SingleComponents/PasswordInput/passwordinput';
const StyledFormPasswordInput = styled.div`
`;

function FormPasswordInput(props) {

    const {label, value, placeholder, onChange, inputStyle, disabled, validated, onPressEnter, ...restProps} = props;

    return <StyledFormPasswordInput {...restProps}>
            <Text style={{fontSize: 13, marginBottom: 5}}>{props.label}</Text>
            <PasswordInput placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} onPressEnter={onPressEnter} disabled={disabled} validated={validated} style={inputStyle} showPassword={restProps.showPassword ? true : false}></PasswordInput>
        </StyledFormPasswordInput>
}

export default FormPasswordInput;