import styled from 'styled-components';

const StyledText = styled.p`
    color: ${(props) => props.theme.color.mainTextColor};
    font: ${(props) => props.theme.fonts.acuminPro};
    margin: 0;
`;

function Text(props) {
    
    return (
        <StyledText {...props}></StyledText>
    )
}

export default Text;