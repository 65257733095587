import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../SingleComponents/PrimaryButton/PrimaryButton';
import Text from '../../SingleComponents/Text/text';
import UpdateInspectionPage from '../../../Pages/UpdateInspectionPage/updateinspectionpage';

const StyledAreaList = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 13px 15px 13px 50px;
    border: solid 1px ${props => props.theme.color.borderGray};
    border-radius: 5px 5px 0px 0px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' width='13' height='13' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    background-position: left 20px top 50%;
    box-sizing: border-box;

    :focus {
        outline: none !important;
        border:1px solid ${(props) => props.theme.color.secondary};
    }
`;

const AreasTable = styled.div`
    overflow-y: auto;
    height: 100%;

    div:nth-child(2n+1) {
        background: #FAFAFB 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:nth-child(2n) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:last-child {
        border: 1px solid #DFDFDF;
    }
`;

const AreaItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;

    padding: 10px 15px;
    box-sizing: border-box;

    ${({ selected }) => selected === true && `
        background: #DFDFDF 0% 0% no-repeat padding-box !important;
    `}
`;

const Ellipse = styled.div`
    width: 12px;
    height: 12px;
    border: none !important;
    border-radius: 6px;
`;

function AreaList(props) {

    const { user, property, areas, onDeletedArea, onUpdatedArea, onViewAreaDetails, ...restProps } = props;

    const [searchText, setSearchText] = useState('');
    const [selectedAreaIndex, setSelectedAreaIndex] = useState(-1);
    const [isInspectionEditMode, setIsInspectionEditMode] = useState(false);
    const [filteredAreas, setFilteredAreas] = useState([]);

    const onViewDetails = (isInspection, area) => {
        if (isInspection) {
            setIsInspectionEditMode(true);
        } else {
            onViewAreaDetails(area);
        }
    }

    useEffect(() => {
        setFilteredAreas(areas.filter((a) => {
            return a.AreaName.toLowerCase().includes(searchText);
        }));
    }, [searchText, areas]);

    return <StyledAreaList {...restProps}>
        <SearchInput placeholder='Search for an area' onChange={(e) => setSearchText(e.target.value)}/>
        <AreasTable>
            {areas.filter((a) => {
                return a.AreaName.toLowerCase().includes(searchText);
            }).map((a, idx) => {
                let isInspection = a.AreaDetails.find((d) => d.FieldEntryID === null) !== undefined;
                let areaColor = '#00000000';
                if (!isInspection) {
                    let areaDetailColorRow = a.AreaDetails.find((d) => d.FieldEntryID === 96);
                    if (areaDetailColorRow) {
                        if (areaDetailColorRow.FieldValue === '1') {
                            areaColor = '#3DC83D';
                        } else if (areaDetailColorRow.FieldValue === '2') {
                            areaColor = '#F3B41E';
                        } else if (areaDetailColorRow.FieldValue === '3') {
                            areaColor = '#FF1616';
                        }
                    }
                }
                return <AreaItem key={idx} selected={selectedAreaIndex===idx} onClick={(e) => setSelectedAreaIndex(idx)}>
                    {isInspection && <Text style={{fontSize: 13, marginLeft: 30, fontWeight: selectedAreaIndex===idx ? 600 : 400}}>{a.AreaName}</Text>}
                    {!isInspection && <>
                        <Ellipse style={{backgroundColor: areaColor}} />
                        <Text style={{fontSize: 13, marginLeft: 18, fontWeight: selectedAreaIndex===idx ? 600 : 400}}>{a.AreaName}</Text>
                    </>}
                    {(selectedAreaIndex===idx || isInspection) && <PrimaryButton caption='View details' onClick={(e) => onViewDetails(isInspection, filteredAreas[selectedAreaIndex])} style={{padding: 0, height: 35, width: 70, marginLeft: 'auto', marginRight: 0, fontSize: 11}}></PrimaryButton>}
                </AreaItem>
            })}
        </AreasTable>
        {isInspectionEditMode && <UpdateInspectionPage user={user} property={property} area={filteredAreas[selectedAreaIndex]} onClose={() => setIsInspectionEditMode(false)} onUpdatedArea={onUpdatedArea} onDeletedArea={onDeletedArea}/>}
    </StyledAreaList>
}

export default AreaList;