import apisauce from 'apisauce';

export default (token) => {
    const api = apisauce.create({
        baseURL: `${process.env.REACT_APP_API}/db`,
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': `${token}`
        },
        responseType: 'json'
    });

    const downloadApi = apisauce.create({
        baseURL: `${process.env.REACT_APP_API}/db`,
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': `${token}`
        },
        responseType: 'arraybuffer'
    });

    const uploadApi = apisauce.create({
        baseURL: `${process.env.REACT_APP_API}/db`,
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': `${token}`
        },
        responseType: 'arraybuffer'
    });

    const fetchUserList = () => api.get('fetchUserList');
    const addUser = (userData) => api.post('addUser', userData);
    const updateUser = (userData) => api.post('updateUser', userData);
    const deleteUser = (userData) => api.post('deleteUser', userData);
    const updateUserPassword = (userData) => api.post('updateUserPassword', userData);

    const fetchCompanyList = (userData) => api.get('fetchCompanyList', userData);
    const fetchUsersOfCompany = (companyData) => api.get('fetchUsersOfCompany', companyData);
    const addCompany = (companyData) => api.post('addCompany', companyData);
    const updateCompany = (companyData) => api.post('updateCompany', companyData);
    const deleteCompany = (companyData) => api.post('deleteCompany', companyData);

    const fetchPropertiesOfCompany = (companyData) => api.get('fetchPropertiesOfCompany', companyData);
    const addProperty = (propertyData) => api.post('addProperty', propertyData);
    const updateProperty = (propertyData) => api.post('updateProperty', propertyData);
    const deleteProperty = (propertyData) => api.post('deleteProperty', propertyData);
    
    const fetchAreasOfProperty = (propertyData) => api.get('fetchAreasOfProperty', propertyData);
    const addArea = (areaData) => api.post('addArea', areaData);
    const updateArea = (areaData) => api.post('updateArea', areaData);
    const deleteArea = (areaData) => api.post('deleteArea', areaData);
    const removeImageFromArea = (areaData) => api.post('removeImageFromArea', areaData);
    
    const updateInspectionDate = (propertyData) => api.post('updateInspectionDate', propertyData);

    const uploadImage = (formData) => uploadApi.post('uploadImage', formData);

    return {
        fetchUserList,
        addUser,
        updateUser,
        deleteUser,
        updateUserPassword,

        fetchCompanyList,
        fetchUsersOfCompany,
        addCompany,
        updateCompany,
        deleteCompany,

        fetchPropertiesOfCompany,
        addProperty,
        updateProperty,
        deleteProperty,
        
        fetchAreasOfProperty,
        addArea,
        updateArea,
        deleteArea,
        removeImageFromArea,

        updateInspectionDate,

        uploadImage,
    };
};
