import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const StyledCustomImage = styled.div`
    position: relative;
    display: flex;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 1px 1px 3px 0px;
`;

const TrashIcon = styled.div`
    position: absolute;
    width: 18px;
    height: 18px;
    bottom: 10px;
    right: 10px;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 3px 0px;
    marginTop: auto;
`;

function CustomImage(props) {

    const { disabled, onRemove, onZoom, ...restProps } = props;

    const [onHover, setOnHover] = useState(false);

    const onClickTrash = (e) => {
        onRemove();
        e.stopPropagation();

    }

    const onClickImage = (e) => {
        onZoom();
        e.stopPropagation();
    }

    return <StyledCustomImage onClick={(e) => onClickImage(e)}>
        <img {...restProps} onMouseEnter={(e) => setOnHover(true)} onMouseLeave={(e) => setOnHover(false)}/>
        {(!disabled && onHover) && <TrashIcon onMouseEnter={(e) => setOnHover(true)} onClick={(e) => onClickTrash(e)}>
            <FontAwesomeIcon style={{width: 13, height: 13, background: 'transparent', paddingLeft: 2, paddingBottom: 3}} icon={faTrashAlt} />
        </TrashIcon>}
    </StyledCustomImage>
}

export default CustomImage;