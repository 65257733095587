import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import Text from '../../components/SingleComponents/Text/text';
import FormInput from "../../components/CombinedComponents/FormInput/FormInput";
import FormPasswordInput from '../../components/CombinedComponents/FormPasswordInput/FormPasswordInput';
import CloseButton from '../../components/SingleComponents/CloseButton/closebutton';
import FormCheckBox from '../../components/CombinedComponents/FormCheckBox/FormCheckBox';
import { NotificationManager } from 'react-notifications';
import request from '../../api/request';

const StyledAddUserPage = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const AddUserModal = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 5px;
    width: 513px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
    height: fit-content;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;

    @media (max-width: 820px) {
        width: inherit;
        margin: 20px;
        padding: 20px;
      }
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 12px;
`;

function AddUserPage(props) {

    const { user, users, onClose, onAddedUser, ...restProps } = props;

    const [needsValidation, setNeedsValidation] = useState(false);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const onChangeUsername = (txt) => {
        setUserName(txt);
        setNeedsValidation(false);
    }

    const onChangePassword = (txt) => {
        setPassword(txt);
        setNeedsValidation(false);
    }

    const onAddUser = async () => {
        if (username === '' || password === '') {
            setNeedsValidation(true);
        } else {
            setIsSaving(true);

            let userData = {};
            userData.Username = username;
            userData.Password = password;
            userData.Type = isAdmin;
            userData.Status = true;

            const resp = (await request('database', 'addUser', [userData]));
            if (resp.ok) {
                NotificationManager.success('Successfully added a user');
                onAddedUser(resp.data.UserID, username, password, isAdmin, true);
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
            setIsSaving(false);
        }
    };

    return <StyledAddUserPage {...restProps} onClick={onClose}>
        <AddUserModal onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 20, fontWeight: 600}}>Add new user</Text>
                <CloseButton onClick={onClose} style={{marginLeft: 'auto'}}/>
            </div>
            <FormInput label='Username' value={username} onChange={onChangeUsername}></FormInput>
            {(needsValidation === true && username === '') && <ErrorMessage>Please enter username</ErrorMessage>}
            <FormPasswordInput label='Password' showPassword={true} style={{marginTop: 17}} value={password} onChange={onChangePassword}></FormPasswordInput>
            {(needsValidation === true && password === '') && <ErrorMessage>Please enter password</ErrorMessage>}
            <Text style={{fontSize: 13}}>User type</Text>
            <FormCheckBox label='Administrator' checked={isAdmin} onCheck={(val) => setIsAdmin(val)} style={{marginTop: 8}}></FormCheckBox>
            <FormCheckBox label='Basic' checked={!isAdmin} onCheck={(val) => setIsAdmin(!val)} style={{marginTop: 5}}></FormCheckBox>
            <div style={{display: 'flex'}}>
                <SecondaryButton caption='Cancel' onClick={onClose} style={{width: 70, height: 33, marginLeft: 'auto'}}></SecondaryButton>
                <PrimaryButton caption='Create' isLoading={isSaving} onClick={onAddUser} style={{width: 70, height: 33, marginLeft: 10}}></PrimaryButton>
            </div>
        </AddUserModal>
    </StyledAddUserPage>
}

export default AddUserPage;