import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const StyledThirdButton = styled.button`
    background-color: #F8F8F8;
    color: ${(props) => props.theme.color.mainTextColor};
    font-size: 12px;
    border: none;
    border-radius: 5px;
    padding: 7px 13px;
    font-family: ${(props) => props.theme.fonts.SegoeUI};
    cursor: pointer;
    user-select: none;

    &:hover:enabled {
        background-color: #FFFFFF;
    }
    :active:enabled {
        box-shadow: 0 0 3px #FFFFFF;
    }
    :disabled {
        color: ${(props) => props.theme.color.gray};
        background-color: ${(props) => props.theme.color.disabledgray};
        border: solid 1px ${(props) => props.theme.color.disabledgray};
    }

    @media (max-width: 820px) {
        width: 100%;
      }
`;

function ThirdButton(props) {

    const {caption, isLoading, ...restProps} = props;

    return (
        <StyledThirdButton {...restProps}>
            {isLoading && <FontAwesomeIcon icon={faSpinner} className='spinner' spin style={{marginRight: 5}}></FontAwesomeIcon>}
            {(restProps.icon) && <FontAwesomeIcon icon={restProps.icon} style={{marginRight: 15}}></FontAwesomeIcon>}
            {caption}
        </StyledThirdButton>
    )
}

export default ThirdButton;