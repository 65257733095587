import styled from 'styled-components';
import { useState, useEffect } from "react";
import Text from '../../components/SingleComponents/Text/text';
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import request from '../../api/request';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import UserList from '../../components/CombinedComponents/UserList/userlist';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import AddUserPage from '../AddUserPage/adduserpage';
import 'react-notifications/lib/notifications.css';

const StyledUsersPage = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 40px;
    align-items: center;

    display: flex;
    flex-direction: column;

    @media (max-width: 820px) {
        padding: 20px;
        top: 88px;
        position: relative;
      }
`;

function Userspage(props) {

    let loadedPage = false;
    const { user } = props;

    const [userList, setUserList] = useState([]);

    const [isAddUserMode, setIsAddUserMode] = useState(false);

    const onDeletedUser = (userID) => {
        let users = [...userList];

        for (let i = 0; i < users.length; i ++) {
            if (users[i].UserID === userID) {
                users.splice(i, 1);
                break;
            }
        }

        setUserList(users);
    }

    const onUpdatedUser = (userID, type, status) => {
        let users = [...userList];

        for (let i = 0; i < users.length; i ++) {
            if (users[i].UserID === userID) {
                users[i].Type = type;
                users[i].Status = status;
                break;
            }
        }

        setUserList(users);
    }

    const onUpdatedPassword = (userID, password) => {
        let users = [...userList];

        for (let i = 0; i < users.length; i ++) {
            if (users[i].UserID === userID) {
                users[i].Password = password;
                break;
            }
        }

        setUserList(users);
    }

    const onAddedUser = (userID, userName, password, userType, userStatus) => {
        let users = [...userList];

        users.unshift({
            UserID: userID,
            Username: userName,
            Password: password,
            Type: userType,
            Status: userStatus
        });

        setUserList(users);
        setIsAddUserMode(false);
    }

    useEffect(() => {
        if (!loadedPage) {
            loadedPage = true;
            async function preLoadUsers() {
                try {
                    let users = [];
                    let resp = (await request('database', 'fetchUserList')).data;
                    resp.map((user) => {
                        users.push(user);
                    });
                    setUserList(users);
                } catch (e) {
                    NotificationManager.error("Failed to fetch user list");
                }
            }

            preLoadUsers();
        }
    }, []);

    return <StyledUsersPage>
        <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
            <Text style={{fontSize: 20, fontWeight: 600}}>List of users</Text>
            {user.Type && <PrimaryButton icon={faEdit} caption='Add new user' onClick={() => setIsAddUserMode(true)} style={{width: 130, marginLeft: 'auto'}}></PrimaryButton>}
        </div>
        <UserList style={{marginTop: 30}} users={userList} user={user} onDeletedUser={onDeletedUser} onUpdatedUser={onUpdatedUser} onUpdatedPassword={onUpdatedPassword}></UserList>
        {isAddUserMode && <AddUserPage user={user} users={userList} onAddedUser={onAddedUser} onClose={() => setIsAddUserMode(false)}/>}
    </StyledUsersPage>
}

export default Userspage;