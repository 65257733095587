import styled from 'styled-components';
import Text from '../../SingleComponents/Text/text';
import Input from '../../SingleComponents/Input/input';

const StyledFormInput = styled.div`
`;

function FormInput(props) {

    const {label, value, placeholder, onChange, inputStyle, disabled, validated, onPressEnter, ...restProps} = props;

    return <StyledFormInput {...restProps}>
            <Text style={{fontSize: 13, marginBottom: 5}}>{props.label}</Text>
            <Input placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} onPressEnter={onPressEnter} disabled={disabled} validated={validated} style={inputStyle}></Input>
        </StyledFormInput>
}

export default FormInput;