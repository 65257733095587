import styled from "styled-components";
import { keyframes } from "styled-components";

const StyledColorCheckBox = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    user-select: none;
`;

const Input = styled.input`
    height: 0;
    width: 0;
    opacity: 0;
    z-index: -1;
`;

const Label = styled.label`
    margin-right: 10px;
    position: relative;
    display: inline-block;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const rotate = keyframes`
    from {
        opacity: 0;
        transform: rotate(0deg);
    }
    to {
        opacity: 1;
        transform: rotate(45deg);
    }
`;

const Indicator = styled.div`
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    position: absolute;
    top: 3px;
    left: 0;
    
    border-radius: 6px;
    background-color: ${(props) => props.color};

    ${Input}:not(:disabled):checked & {
        background: #d1d1d1;
    }

    &::after {
        content: "";
        position: absolute;
        display: none;
    }

    ${Input}:checked + &::after {
        display: block;
        top: 4px;
        left: 8px;
        width: 20%;
        height: 40%;
        border: solid white;
        border-width: 0 3px 3px 0;
        animation-name: ${rotate};
        animation-fill-mode: forwards;
    }

    ${Input}:disabled:checked + &::after {
        display: block;
        top: 4px;
        left: 8px;
        width: 20%;
        height: 40%;
        border: solid white;
        border-width: 0 3px 3px 0;
        animation-name: ${rotate};
        animation-fill-mode: forwards;
    }

    &::disabled {
        cursor: not-allowed;
    }

    ${({ disabled }) => disabled === true && `
        border-color: #C7C7C7;
    `}
`;


function ColorCheckBox(props) {

    const {color, checked, disabled, onCheck, ...restProps} = props;

    return (
        <StyledColorCheckBox {...restProps}>
            <Label disabled={disabled}>
            <Input
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={() => {onCheck(!checked)}}
            />
            <Indicator disabled={disabled} checked={checked} color={color}/>
            </Label>
        </StyledColorCheckBox>
    )
}

export default ColorCheckBox;