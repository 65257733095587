import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import Text from '../../components/SingleComponents/Text/text';
import FormInput from "../../components/CombinedComponents/FormInput/FormInput";
import CloseButton from '../../components/SingleComponents/CloseButton/closebutton';
import FormCheckBox from '../../components/CombinedComponents/FormCheckBox/FormCheckBox';
import ConfirmationModal from '../../components/CombinedComponents/ConfirmationModal/confirmationmodal';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications';
import AddUserToCompanyPage from '../AddUserToCompanyPage/addusertocompanypage';
import request from '../../api/request';

const StyledUpdateCompanyPage = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const AddCompanyModal = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 5px;
    width: 513px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
    height: fit-content;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;

    @media (max-width: 820px) {
        width: inherit;
        margin: 20px;
        padding: 20px;
      }
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 12px;
`;

const UsersTable = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 170px;
    border: 1px solid ${props => props.theme.color.borderGray};
    border-radius: 5px;

    div:nth-child(2n) {
        background: #FAFAFB 0% 0% no-repeat padding-box;
        border-bottom: 1px solid #DFDFDF;
    }

    div:nth-child(2n+1) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-bottom: 1px solid #DFDFDF;
    }
`;

const UserItem = styled.div`
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;

    padding-left: 15px;
    box-sizing: border-box;

    ${({ selected }) => selected === true && `
        font-weight: 600;
        background: #DFDFDF 0% 0% no-repeat padding-box !important;
    `}
`;

const TextButton = styled.span`
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.theme.color.primary};

    &:hover {
        text-decoration: underline;
    }
`;

function UpdateCompanyPage(props) {

    let loadedPage = false;
    const { company, onClose, onDeletedCompany, onUpdatedCompany, ...restProps } = props;

    const [needsValidation, setNeedsValidation] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [usersOfCompany, setUsersOfCompany] = useState([]);
    const [selectedUserIdx, setSelectedUserIdx] = useState(-1);
    const [isSaving, setIsSaving] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [isAddUserToCompanyMode, setIsAddUserToCompanyMode] = useState(false);

    const onChangeCompanyName = (txt) => {
        setCompanyName(txt);
        setNeedsValidation(false);
    }

    const onUpdateCompany = async () => {
        if (companyName === '') {
            setNeedsValidation(true);
        } else {
            setIsSaving(true);
            let companyData = {};
            companyData.CompanyID = company.CompanyID;
            companyData.CompanyName = companyName;
            companyData.Status = isActive;
            companyData.UserIDs = [];
            for (let i = 0; i < usersOfCompany.length; i ++) {
                companyData.UserIDs.push(usersOfCompany[i].UserID);
            }

            const resp = (await request('database', 'updateCompany', [companyData]));
            if (resp.ok) {
                NotificationManager.success('Successfully updated a company');
                onUpdatedCompany(company.CompanyID, companyName, isActive);

                onClose();
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
            setIsSaving(false);
        }
    };

    const onDeleteCompany = async () => {
        setShowModal(false);
        setIsDeleting(true);
        let companyData = {};
        companyData.CompanyID = company.CompanyID;
        companyData.CompanyName = company.CompanyName;

        const resp = (await request('database', 'deleteCompany', [companyData]));
        if (resp.ok) {
            NotificationManager.success('Successfully deleted a company');
            onDeletedCompany(company.CompanyID);

            onClose();
        } else
        {
            console.log(resp.data);
            NotificationManager.error(resp.data);
        }
        setIsDeleting(false);
    };

    const removeUserFromCompany = (e, userID) => {
        let users = [...usersOfCompany];

        for (let i = 0; i < users.length; i ++) {
            if (users[i].UserID === userID) {
                users.splice(i, 1);
                break;
            }
        }

        setUsersOfCompany(users);
    }

    const onAddUserToCompany = (e, userToAdd) => {
        setIsAddUserToCompanyMode(false);

        if (userToAdd) {
            let users = [...usersOfCompany];
            users.push(userToAdd);
            setUsersOfCompany(users);
        }
        e.stopPropagation();
    }

    useEffect(() => {
        if (!loadedPage) {
            loadedPage = true;
            setCompanyName(company.CompanyName);
            setIsActive(company.Status);
            async function preLoadUsersOfCompany() {
                try {
                    let users = [];
                    let companyData = {};
                    companyData.CompanyID = company.CompanyID;
                    let resp = (await request('database', 'fetchUsersOfCompany', [companyData])).data;
                    resp.map((user) => {
                        users.push(user);
                    });
                    setUsersOfCompany(users);
                } catch (e) {
                    NotificationManager.error("Failed to fetch users of company");
                }
            }

            preLoadUsersOfCompany();
        }
    }, []);

    return <StyledUpdateCompanyPage {...restProps} onClick={onClose}>
        <AddCompanyModal onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 20, fontWeight: 600}}>Edit company</Text>
                <CloseButton onClick={onClose} style={{marginLeft: 'auto'}}/>
            </div>
            <FormInput label='Company name' value={companyName} onChange={onChangeCompanyName} ></FormInput>
            {(needsValidation === true && companyName === '') && <ErrorMessage>Please enter a company name</ErrorMessage>}
            <Text style={{fontSize: 13}}>Assigned users to company</Text>
            <UsersTable>
                {usersOfCompany.map((u, idx) => {
                    return <UserItem key={idx} selected={selectedUserIdx===idx} onClick={(e) => setSelectedUserIdx(idx)}>
                        <Text style={{fontSize: 12}}>{u.Username}</Text>
                        <TextButton style={{marginLeft: 'auto', marginRight: 12}} onClick={(e) => removeUserFromCompany(e, u.UserID)}>Remove</TextButton>
                    </UserItem>
                })}
            </UsersTable>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 13}}>Company status</Text>
                <PrimaryButton caption='Add user' onClick={(e) => setIsAddUserToCompanyMode(true)} icon={faPlus} style={{width: 100, marginLeft: 'auto'}}></PrimaryButton>
            </div>
            <FormCheckBox label='Active' checked={isActive} onCheck={(val) => setIsActive(val)} style={{marginTop: 5}}></FormCheckBox>
            <FormCheckBox label='Inactive' checked={!isActive} onCheck={(val) => setIsActive(!val)} style={{marginTop: 5}}></FormCheckBox>
            <div style={{marginTop: 40, display: 'flex'}}>
                <SecondaryButton caption='Delete' isLoading={isDeleting} onClick={(e) => setShowModal(true)} style={{width: 70, height: 33}}></SecondaryButton>
                <SecondaryButton caption='Cancel' onClick={onClose} style={{width: 70, height: 33, marginLeft: 'auto'}}></SecondaryButton>
                <PrimaryButton caption='Update' isLoading={isSaving} onClick={(e) => onUpdateCompany()} style={{width: 70, height: 33, marginLeft: 10}}></PrimaryButton>
            </div>
        </AddCompanyModal>
        {isAddUserToCompanyMode && <AddUserToCompanyPage selectedUsersForCompany={usersOfCompany} onAddUserToCompany={(e, userID) => onAddUserToCompany(e, userID)} />}
        {showModal && <ConfirmationModal title='Delete company' text='Are you sure you want to delete this company?' yesBtnText='Delete' onConfirm={(e) => onDeleteCompany()} onCancel={(e) => setShowModal(false)}/>}
    </StyledUpdateCompanyPage>
}

export default UpdateCompanyPage;