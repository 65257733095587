import { createReducer, createActions } from 'reduxsauce';
import Cookies from 'js-cookie';
import get from 'lodash/get';

/* --------------------- Types and Action Creators ---------------- */
const { Types, Creators } = createActions({
    setUser: ['user'],
});

export const MainTypes = Types;

Creators.setLogin = user => {
	return dispatch => {
        if (user.token) {
            Cookies.set('transthermal-token', user.token);
        }
        return Promise.resolve(dispatch(Creators.setUser(user)));
    };
};

Creators.setLogout = () => {
    return dispatch => {
        Cookies.remove('transthermal-token');
        return Promise.resolve(dispatch(Creators.setUser(null)));
    };
};

export default Creators;

/* --------------------- Selectors ---------------- */
export const MainSelectors = {
    selectToken: state => state.main.token,
    selectUser: state => state.main.user,
    selectLoggedIn: state => !!get(state, 'main.user'),
    selectNotifications: state => state.main.notifications,
};

/* --------------------- Initial State ----------------- */
export const INITIAL_STATE = {
    user: null,
};

/* ------------------- Reducers --------------------- */
export const setUser = (state, { user }) => ({
    ...state,
    user
});
// });

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_USER]: setUser,
});
