import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import Text from '../../components/SingleComponents/Text/text';
import FormInput from "../../components/CombinedComponents/FormInput/FormInput";
import FormPasswordInput from '../../components/CombinedComponents/FormPasswordInput/FormPasswordInput';
import CloseButton from '../../components/SingleComponents/CloseButton/closebutton';
import FormCheckBox from '../../components/CombinedComponents/FormCheckBox/FormCheckBox';
import ConfirmationModal from '../../components/CombinedComponents/ConfirmationModal/confirmationmodal';
import { NotificationManager } from 'react-notifications';
import request from '../../api/request';

const StyledUpdateUserPage = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const UpdateUserModal = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 5px;
    width: 513px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
    height: fit-content;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;

    @media (max-width: 820px) {
        width: inherit;
        margin: 20px;
        padding: 20px;
      }
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 12px;
`;

function UpdateUserPage(props) {

    let loadedPage = false;

    const { user, onClose, onDeletedUser, onUpdatedUser, onUpdatedPassword, ...restProps } = props;

    const [needsValidation, setNeedsValidation] = useState(false);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

    const onChangeUsername = (txt) => {
        setUserName(txt);
        setNeedsValidation(false);
    }

    const onChangePassword = (txt) => {
        setPassword(txt);
        setNeedsValidation(false);
    }

    const onDeleteUser = async () => {
        setShowModal(false);
        setIsDeleting(true);
        let userData = {};
        userData.UserID = user.UserID;
        userData.Username = username;

        const resp = (await request('database', 'deleteUser', [userData]));
        if (resp.ok) {
            NotificationManager.success('Successfully deleted a user');
        } else
        {
            console.log(resp.data);
            NotificationManager.error(resp.data);
        }
        setIsDeleting(false);

        onClose();
        onDeletedUser(user.UserID);
    };

    const onUpdateUser = async () => {
        if (username === '' || password === '') {
            setNeedsValidation(true);
        } else {
            setIsSaving(true);
            let userData = {};
            userData.UserID = user.UserID;
            userData.Username = username;
            userData.Type = isAdmin;
            userData.Status = isActive;

            const resp = (await request('database', 'updateUser', [userData]));
            if (resp.ok) {
                NotificationManager.success('Successfully updated a user');
                onUpdatedUser(user.UserID, isAdmin, isActive);
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
            setIsSaving(false);
        }
    };

    const onUpdatePassword = async () => {
        if (password === '') {
            setNeedsValidation(true);
        } else {
            setIsUpdatingPassword(true);
            let userData = {};
            userData.UserID = user.UserID;
            userData.Username = username;
            userData.Password = password;

            const resp = (await request('database', 'updateUserPassword', [userData]));
            if (resp.ok) {
                NotificationManager.success('Successfully updated password');
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
            setIsUpdatingPassword(false);
            onUpdatedPassword(user.UserID, password);
        }
    }

    useEffect(() => {
        if (!loadedPage) {
            loadedPage = true;
        }
        if (user) {
            setUserName(user.Username);
            setPassword(user.Password);
            setIsAdmin(user.Type);
            setIsActive(user.Status);
        }
    }, []);

    return <StyledUpdateUserPage {...restProps} onClick={onClose}>
        <UpdateUserModal onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 20, fontWeight: 600}}>User details</Text>
                <CloseButton onClick={onClose} style={{marginLeft: 'auto'}}/>
            </div>
            <FormInput label='Username' disabled={true} value={username} onChange={onChangeUsername}></FormInput>
            {(needsValidation === true && username === '') && <ErrorMessage>Please enter username</ErrorMessage>}
            <div style={{display: 'grid', gridTemplateColumns: '230px 70px', gridColumnGap: 0}}>
                <div>
                    <FormPasswordInput label='Password' showPassword={true} style={{marginTop: 5, width: 70}} value={password} onChange={onChangePassword}></FormPasswordInput>
                    {(needsValidation === true && password === '') && <ErrorMessage>Please enter password</ErrorMessage>}
                </div>
                <SecondaryButton caption='Update password' isLoading={isUpdatingPassword} onClick={onUpdatePassword} style={{height: 40, marginTop: 'auto'}}></SecondaryButton>
            </div>
            <div style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                <div>
                    <Text style={{marginTop: 20, fontSize: 13}}>User type</Text>
                    <FormCheckBox label='Administrator' checked={isAdmin} onCheck={(val) => setIsAdmin(val)} style={{marginTop: 8}}></FormCheckBox>
                    <FormCheckBox label='Basic' checked={!isAdmin} onCheck={(val) => setIsAdmin(!val)} style={{marginTop: 5}}></FormCheckBox>
                </div>
                <div>
                    <Text style={{marginTop: 20, fontSize: 13}}>User status</Text>
                    <FormCheckBox label='Active' checked={isActive} onCheck={(val) => setIsActive(val)} style={{marginTop: 8}}></FormCheckBox>
                    <FormCheckBox label='Inactive' checked={!isActive} onCheck={(val) => setIsActive(!val)} style={{marginTop: 5}}></FormCheckBox>
                </div>
            </div>
            <div style={{marginTop: 20, display: 'flex'}}>
                <SecondaryButton caption='Delete' isLoading={isDeleting} onClick={(e) => setShowModal(true)} style={{width: 70, height: 33}}></SecondaryButton>
                <SecondaryButton caption='Cancel' onClick={onClose} style={{width: 70, height: 33, marginLeft: 'auto'}}></SecondaryButton>
                <PrimaryButton caption='Update' isLoading={isSaving} onClick={onUpdateUser} style={{width: 70, height: 33, marginLeft: 10}}></PrimaryButton>
            </div>
        </UpdateUserModal>
        {showModal && <ConfirmationModal title='Delete user' text='Are you sure you want to delete this user?' yesBtnText='Delete' onConfirm={(e) => onDeleteUser()} onCancel={(e) => setShowModal(false)}/>}
    </StyledUpdateUserPage>
}

export default UpdateUserPage;