import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import Text from '../../components/SingleComponents/Text/text';
import FormInput from "../../components/CombinedComponents/FormInput/FormInput";
import FormTextArea from '../../components/CombinedComponents/FormTextArea/FormTextArea';
import CloseButton from '../../components/SingleComponents/CloseButton/closebutton';
import { NotificationManager } from 'react-notifications';
import request from '../../api/request';

const StyledAddInspectionPage = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const AddInspectionModal = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 5px;
    width: 513px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
    height: fit-content;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;

    @media (max-width: 820px) {
        width: inherit;
        margin: 20px;
        padding: 20px;
      }
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 12px;
`;

function AddInspectionPage(props) {

    let loadedPage = false;
    const { user, property, areas, onClose, onAddedArea, ...restProps } = props;

    const [needsValidation, setNeedsValidation] = useState(false);
    const [inspectionName, setInspectionName] = useState('');
    const [inspectionDescription, setInspectionDescription] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const onChangeInspectionName = (txt) => {
        setInspectionName(txt);
        setNeedsValidation(false);
    }

    const onChangeInspectionDescription = (txt) => {
        setInspectionDescription(txt);
        setNeedsValidation(false);
    }

    const onAddInspection = async () => {
        if (inspectionName === '' || inspectionDescription === '') {
            setNeedsValidation(true);
        } else {
            setIsSaving(true);

            let areaData = {};
            areaData.PropertyID = property.PropertyID;
            areaData.AreaName = inspectionName;
            areaData.AreaDetails = [{
                FieldEntryID: null,
                FieldValue: inspectionDescription
            }];

            const resp = (await request('database', 'addArea', [areaData]));
            if (resp.ok) {
                NotificationManager.success('Successfully added an inspection');
                onAddedArea(resp.data.AreaID, inspectionName, areaData.AreaDetails);

                onClose();
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (!loadedPage) {
            loadedPage = true;
        }
    }, []);

    return <StyledAddInspectionPage {...restProps} onClick={onClose}>
        <AddInspectionModal onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 20, fontWeight: 600}}>Add new area inspection</Text>
                <CloseButton onClick={onClose} style={{marginLeft: 'auto'}}/>
            </div>
            <FormInput label='Inspection name' value={inspectionName} onChange={onChangeInspectionName}></FormInput>
            {(needsValidation === true && inspectionName === '') && <ErrorMessage>Please enter inspection name</ErrorMessage>}
            <FormTextArea label='Inspection description' style={{marginTop: 5}} value={inspectionDescription} onChange={onChangeInspectionDescription} inputStyle={{height: 100}}></FormTextArea>
            {(needsValidation === true && inspectionDescription === '') && <ErrorMessage>Please enter inspection description</ErrorMessage>}
            <div style={{marginTop: 40, display: 'flex'}}>
                <SecondaryButton caption='Cancel' onClick={onClose} style={{width: 70, height: 33, marginLeft: 'auto'}}></SecondaryButton>
                <PrimaryButton caption='Create' isLoading={isSaving} onClick={(e) => onAddInspection()} style={{width: 70, height: 33, marginLeft: 10}}></PrimaryButton>
            </div>
        </AddInspectionModal>
    </StyledAddInspectionPage>
}

export default AddInspectionPage;