import styled from 'styled-components';
import PrimaryButton from '../../SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../SingleComponents/SecondaryButton/SecondaryButton';
import Text from '../../SingleComponents/Text/text';
import CloseButton from '../../SingleComponents/CloseButton/closebutton';

const StyledConfirmationModal = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const ModalWindow = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 5px;
    width: 366px;
    min-height: 252px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;

    @media (max-width: 820px) {
        padding: 20px;
        width: auto;
        min-height: auto;
      }
`;

function ConfirmationModal(props) {

    const { title, text, yesBtnText, onConfirm, onCancel, ...restProps } = props;

    return <StyledConfirmationModal {...restProps} onClick={(e) => {onCancel(); e.stopPropagation();}}>
        <ModalWindow onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 20, fontWeight: 600}}>{title}</Text>
                <CloseButton onClick={onCancel} style={{marginLeft: 'auto'}}/>
            </div>
            <Text style={{marginTop: 40, fontSize: 16, width: 250}}>{text}</Text>
            <div style={{marginTop: 40, display: 'flex'}}>
                <SecondaryButton caption='Cancel' onClick={onCancel} style={{width: 70, height: 33}}></SecondaryButton>
                <PrimaryButton caption={yesBtnText} onClick={onConfirm} style={{width: 70, height: 33, marginLeft: 'auto'}}></PrimaryButton>
            </div>
        </ModalWindow>
    </StyledConfirmationModal>
}

export default ConfirmationModal;