import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const StyledPrimaryButton = styled.button`
    background-color: ${(props) => props.theme.color.primary};
    color: white;
    font-size: 12px;
    border: none;
    border-radius: 5px;
    padding: 7px 13px;
    font-family: ${(props) => props.theme.fonts.SegoeUI};
    cursor: pointer;
    user-select: none;

    &:hover:enabled {
        background-color: ${(props) => props.theme.color.primaryhover};
    }
    :active:enabled {
        border:1px solid ${(props) => props.theme.color.primaryhover};
        box-shadow: 0 0 3px ${(props) => props.theme.color.primaryhover};
    }
    :disabled {
        color: ${(props) => props.theme.color.gray};
        background-color: ${(props) => props.theme.color.disabledgray}
    }

    @media (max-width: 820px) {
        width: 100%;
      }
`;

function PrimaryButton(props) {

    const {caption, isLoading, ...restProps} = props;

    return (
        <StyledPrimaryButton {...restProps}>
            {isLoading && <FontAwesomeIcon icon={faSpinner} className='spinner' spin style={{marginRight: 5}}></FontAwesomeIcon>}
            {(restProps.icon) && <FontAwesomeIcon icon={restProps.icon} style={{marginRight: 15}}></FontAwesomeIcon>}
            {caption}
        </StyledPrimaryButton>
    )
}

export default PrimaryButton;