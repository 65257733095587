import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';

const StyledPasswordInput = styled.input`
    border: solid 1px ${(props) => props.theme.color.borderGray};
    color: ${(props) => props.theme.color.mainTextColor};
    font-family: ${(props) => props.theme.fonts.acuminPro};
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    padding: 12px 12px;
    
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${(props) => props.theme.color.disabledgray};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${(props) => props.theme.color.disabledgray};
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: ${(props) => props.theme.color.disabledgray};
    }

    :focus {
        outline: none !important;
        border:1px solid ${(props) => props.validated === undefined ? props.theme.color.primary : ''};
    }

    :disabled {
        outline: none !important;
        border:1px solid ${(props) => props.theme.color.disabledBorder};
        color: ${(props) => props.theme.color.grayTextColor};
        background-color: ${(props) => props.theme.color.borderGray}
    }

    ${({ validated }) => validated === false && `
        border-color: #dc3545;
        color: #dc3545;
    `}

    ${({ validated }) => validated === true && `
        border-color: #198754;
        color: #198754;
    `}
`;

function PasswordInput(props) {

    const {onPressEnter, ...restProps} = props;

    const [isShowPassword, setIsShowPassword] = useState(false);

    const onKeyPress = (e) => {
        if (e.charCode === 13 && onPressEnter) {
            onPressEnter();
        }
    }
    
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <StyledPasswordInput type={isShowPassword ? '' : 'password'} onKeyPress={onKeyPress} value='123' {...restProps} />
            {restProps.showPassword && <FontAwesomeIcon icon={faEye} onClick={() => setIsShowPassword(!isShowPassword)} style={{marginLeft: 10, cursor: 'pointer'}}></FontAwesomeIcon>}
        </div>
    )
}

export default PasswordInput;