import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const StyledBackButton = styled.button`
    width: 20px;
    height: 20px;
    border: 2px solid #1A1919;
    border-radius: 6px;
    background: white;
    padding-top: 0px;
    padding-left: 2px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    padding-top: 1px;
`;

function BackButton(props) {

    return (
        <StyledBackButton {...props}>
            <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
        </StyledBackButton>
    )
}

export default BackButton;