import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../SingleComponents/PrimaryButton/PrimaryButton';
import Text from '../../SingleComponents/Text/text';
import UpdateUserPage from '../../../Pages/UpdateUserPage/updateuserpage';

const StyledUserList = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 13px 15px 13px 50px;
    border: solid 1px ${props => props.theme.color.borderGray};
    border-radius: 5px 5px 0px 0px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' width='13' height='13' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    background-position: left 20px top 50%;
    box-sizing: border-box;

    :focus {
        outline: none !important;
        border:1px solid ${(props) => props.theme.color.secondary};
    }
`;

const UsersTable = styled.div`
    overflow-y: auto;
    height: 100%;

    div:nth-child(2n+1) {
        background: #FAFAFB 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:nth-child(2n) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:last-child {
        border: 1px solid #DFDFDF;
    }
`;

const UserItem = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    cursor: pointer;
    user-select: none;

    padding: 10px 15px;
    box-sizing: border-box;

    ${({ selected }) => selected === true && `
        background: #DFDFDF 0% 0% no-repeat padding-box !important;
    `}

    @media (max-width: 820px) {
        flex-direction: column;
        -moz-box-align: start;
        align-items: start;
      }
`;

function UserList(props) {

    const { user, users, onDeletedUser, onUpdatedUser, onUpdatedPassword, ...restProps } = props;

    const [searchText, setSearchText] = useState('');
    const [selectedUserIndex, setSelectedUserIndex] = useState(-1);
    const [isUserEditMode, setIsUserEditMode] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const onViewUserDetails = (userIdx) => {
        setIsUserEditMode(true);
    }

    useEffect(() => {
        setFilteredUsers(users.filter((u) => {
            return u.Username.toLowerCase().includes(searchText);
        }));
    }, [searchText, users]);

    return <StyledUserList {...restProps}>
        <SearchInput placeholder='Search for a user' onChange={(e) => setSearchText(e.target.value)}/>
        <UsersTable>
            {users.filter((u) => {
                return u.Username.toLowerCase().includes(searchText);
            }).map((u, idx) => {
                return <UserItem key={idx} selected={selectedUserIndex===idx} onClick={(e) => setSelectedUserIndex(idx)}>
                    <Text style={{fontSize: 13, width: 100, fontWeight: selectedUserIndex===idx ? 600 : 400}}>{u.Type ? 'Admin' : 'Basic'}</Text>
                    <Text style={{fontSize: 13, fontWeight: selectedUserIndex===idx ? 600 : 400}}>{u.Username}</Text>
                    {(selectedUserIndex===idx) && <PrimaryButton caption='View details' onClick={(e) => onViewUserDetails(idx)} style={{marginLeft: 'auto', marginRight: 18, fontSize: 11}}></PrimaryButton>}
                </UserItem>
            })}
        </UsersTable>
        {isUserEditMode && <UpdateUserPage user={filteredUsers[selectedUserIndex]} onClose={() => setIsUserEditMode(false)} onUpdatedUser={onUpdatedUser} onUpdatedPassword={onUpdatedPassword} onDeletedUser={onDeletedUser}/>}
    </StyledUserList>
}

export default UserList;