import Cookies from 'js-cookie';
import DatabaseApi from './DatabaseApi';
import AuthApi from './AuthApi';

// import { getStore } from '../redux/store';
// import MainActions from '../redux/MainRedux';

const API_MAP = {
  database: DatabaseApi,
  auth: AuthApi
};

export default async function request(apiName, func, params = [], apiOptions) {
  // const store = getStore();
  const createApi = API_MAP[apiName];
  const token = Cookies.get('transthermal-token');

  if (!createApi) {
    throw new Error(`API ${apiName} not found`);
  }

  const api = createApi(token, apiOptions);
  const resp = await api[func](...params);
  return resp;
}
