import styled from "styled-components";
import { create } from "apisauce";
import { useState, useEffect, useRef } from "react";
import PrimaryButton from "../../components/SingleComponents/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../components/SingleComponents/SecondaryButton/SecondaryButton";
import Text from "../../components/SingleComponents/Text/text";
import FormInput from "../../components/CombinedComponents/FormInput/FormInput";
import FormTextArea from "../../components/CombinedComponents/FormTextArea/FormTextArea";
import ConfirmationModal from "../../components/CombinedComponents/ConfirmationModal/confirmationmodal";
import ColorCheckBox from "../../components/CombinedComponents/FormCheckBox/ColorCheckBox";
import CustomImage from "../../components/CombinedComponents/CustomImage/customimage";
import FullScreenImage from "../FullScreenImage/fullscreenimage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFileUpload, faTableTennisPaddleBall } from '@fortawesome/free-solid-svg-icons';
import NotificationManager from "react-notifications/lib/NotificationManager";
import request from "../../api/request";
import { Navigate, useNavigate } from "react-router-dom";

const StyledAddEditAreaEntryPage = styled.div`
    width: 100%;
    height: 100%;
`;

const StyledAddEditAreaEntryPageMobile = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    margin: 20px 0 20px 0;

    @media (max-width: 820px) {
        display: grid;
        grid-template-columns: auto;
        gap: 0px;
      }
`;

function AddEditAreaEntryPage(props) {

    const navigate = useNavigate();

    const { user, property, areas, area, isEditMode, onAddedArea, onUpdatedArea, onDeletedArea, onClose, ...restProps } = props;

    const FIELD_ENTRY_ID_AREA_NAME = 67;
    const FIELD_ENTRY_ID_PHOTO_FILENAMES = 68;
    const FIELD_ENTRY_ID_FLOOR_LEVEL = 81;
    const FIELD_ENTRY_ID_ROOM_NUMBER = 82;
    const FIELD_ENTRY_ID_ROOM_NAME = 83;
    const FIELD_ENTRY_ID_ITEM_DESCRIPTION = 84;
    const FIELD_ENTRY_ID_EXTENT = 85;
    const FIELD_ENTRY_ID_LOCATION = 86;
    const FIELD_ENTRY_ID_CONDITION = 87;
    const FIELD_ENTRY_ID_SAMPLE_NUMBER = 88;
    const FIELD_ENTRY_ID_ASBESTOS_TYPE = 89;
    const FIELD_ENTRY_ID_MATERIAL_ASSESSMENT = 90;
    const FIELD_ENTRY_ID_RECOMMENDATION = 91;
    const FIELD_ENTRY_ID_WITHIN_TIMESCALE = 92;
    const FIELD_ENTRY_ID_PHOTO_DIAG_REFERENCE = 93;
    const FIELD_ENTRY_ID_LAST_INSPECTED = 94;
    const FIELD_ENTRY_ID_NEXT_INSPECTION_DUE = 95;
    const FIELD_ENTRY_ID_AREA_COLOR = 96;

    const uploadFile= useRef(null);
    
    const [isSaving, setIsSaving] = useState(false);
    const [showDeleteAreaModal, setShowDeleteAreaModal] = useState(false);
    const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);
    const [deletePhotoIdx, setDeletePhotoIdx]  = useState(-1);
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentArea, setCurrentArea] = useState({
        PropertyID: 0,
        AreaID: 0,
        AreaName: '',
        AreaDetails: [
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_AREA_NAME, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_PHOTO_FILENAMES, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_FLOOR_LEVEL, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_ROOM_NUMBER, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_ROOM_NAME, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_ITEM_DESCRIPTION, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_EXTENT, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_LOCATION, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_CONDITION, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_SAMPLE_NUMBER, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_ASBESTOS_TYPE, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_MATERIAL_ASSESSMENT, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_RECOMMENDATION, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_WITHIN_TIMESCALE, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_PHOTO_DIAG_REFERENCE, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_LAST_INSPECTED, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_NEXT_INSPECTION_DUE, FieldValue: ''},
            {AreaID: 0, FieldEntryID: FIELD_ENTRY_ID_AREA_COLOR, FieldValue: ''},
        ]
    });

    const [photoFiles, setPhotoFiles] = useState([]);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [urlsToRevoke, setUrlsToRevoke] = useState([]);

    const [showFullScreenImage, setShowFullScreenImage] = useState(false);
    const [fullScreenImageUrl, setFullScreenImageUrl] = useState(null);

    const getStringFromDate = (date) => {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [(mm>9 ? '' : '0') + mm,
                (dd>9 ? '' : '0') + dd,
                date.getFullYear()
                ].join('-');
    }

    const getAreaFieldValue = (fieldEntryID) => {
        if (currentArea.AreaDetails) {
            let fieldRow = currentArea.AreaDetails.find((d) => d.FieldEntryID === fieldEntryID);
            if (fieldRow) {
                return fieldRow.FieldValue ? fieldRow.FieldValue : '';
            }
        }

        return '';
    }

    const setAreaFieldValue = (fieldEntryID, fieldValue) => {
        let tempArea = JSON.parse(JSON.stringify(currentArea));
        if (fieldEntryID === FIELD_ENTRY_ID_AREA_NAME) {
            tempArea.AreaName = fieldValue;
        }
        let valueSaved = false;
        for (let i = 0; i < tempArea.AreaDetails.length; i ++) {
            if (tempArea.AreaDetails[i].FieldEntryID === fieldEntryID) {
                tempArea.AreaDetails[i].FieldValue = fieldValue;
                valueSaved = true;
                break;
            }
        }
        if (!valueSaved) {
            tempArea.AreaDetails.push({
                AreaID: currentArea.AreaID,
                FieldEntryID: fieldEntryID,
                FieldValue: fieldValue
            });
        }
        setCurrentArea(tempArea);
    }
    
    const onSave = async () => {
        setIsSaving(true);
        
        const formData = new FormData();
        photoFiles.forEach((file) => {
            if (file) {
                formData.append('images', file);
            }
        });

        let uploadedFileNames = '';
        const resp = (await request('database', 'uploadImage', [formData]));
        if (resp.ok) {
            uploadedFileNames = resp.data;
            const decoder = new TextDecoder('utf-8');
            uploadedFileNames = decoder.decode(resp.data);
        } else {
            console.log(resp.data);
            NotificationManager.error('Failed to upload images: ' + resp.data);
            return;
        }

        let tempArea = JSON.parse(JSON.stringify(currentArea));
        tempArea.PropertyID = property.PropertyID;
        if (uploadedFileNames.length > 0) {
            for (let i = 0; i < tempArea.AreaDetails.length; i ++) {
                if (tempArea.AreaDetails[i].FieldEntryID === FIELD_ENTRY_ID_PHOTO_FILENAMES) {
                    if (tempArea.AreaDetails[i].FieldValue.length > 0) {
                        tempArea.AreaDetails[i].FieldValue += ',' + uploadedFileNames;
                    } else {
                        tempArea.AreaDetails[i].FieldValue += uploadedFileNames;
                    }
                    break;
                }
            }
        }
        setCurrentArea(tempArea);

        if (isEditMode) {
            const resp = (await request('database', 'updateArea', [tempArea]));
            if (resp.ok) {
                NotificationManager.success('Successfully updated an area');
                onUpdatedArea(tempArea.AreaID, tempArea.AreaName, tempArea.AreaDetails);

                let newFileNames = tempArea.AreaDetails.find((d) => d.FieldEntryID === FIELD_ENTRY_ID_PHOTO_FILENAMES).FieldValue;
                if (newFileNames.length > 0) {
                    newFileNames = newFileNames.split(',');
                } else {
                    newFileNames = [];
                }

                let revokeUrls = JSON.parse(JSON.stringify(urlsToRevoke));
                for (let i = 0; i < photoUrls.length; i ++) {
                    if (photoFiles[i]) {
                        revokeUrls.push(photoUrls[i]);
                    }
                }
                setUrlsToRevoke(revokeUrls);
                let newPhotoUrls = [];
                for (let i = 0; i < newFileNames.length; i ++) {
                    newPhotoUrls.push(`${process.env.REACT_APP_API}/` + newFileNames[i] + '.jpg');
                }
                setPhotoUrls(newPhotoUrls);
                setPhotoFiles([]);
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
        } else {
            const resp = (await request('database', 'addArea', [tempArea]));
            if (resp.ok) {
                NotificationManager.success('Successfully added an area');
                onAddedArea(resp.data.AreaID, tempArea.AreaName, tempArea.AreaDetails);
                navigate(`/companies/${property.CompanyID}/properties/${property.PropertyID}/areas/resp.data.AreaID/editArea`);
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
        }
        setIsSaving(false);
    }

    const onDeleteArea = async () => {
        setShowDeleteAreaModal(false);
        setIsDeleting(true);

        const resp = (await request('database', 'deleteArea', [currentArea]));
        if (resp.ok) {
            NotificationManager.success('Successfully deleted an area');
            onDeletedArea(currentArea.AreaID);

            onClose();
        } else
        {
            console.log(resp.data);
            NotificationManager.error(resp.data);
        }
        setIsDeleting(false);
    }

    const onUploadFile = (event) => {
        // event.stopPropagation();
        // event.preventDefault();
        var files = event.target.files;

        let uploadedPhotoUrls = JSON.parse(JSON.stringify(photoUrls));
        for (let i = 0; i < files.length; i ++) {
            const fileUrl = URL.createObjectURL(files[i])
            uploadedPhotoUrls.push(fileUrl);
        }
        setPhotoUrls(uploadedPhotoUrls);

        let uploadedPhotoFiles = [...photoFiles, ...files];
        setPhotoFiles(uploadedPhotoFiles);
    }

    const zoomImage = (idx) => {
        setFullScreenImageUrl(photoUrls[idx]);
        setShowFullScreenImage(true);
    }

    const removeImage = async (idx) => {
        if (photoFiles[idx]) { // local file
        } else { // server file
            let tempArea = JSON.parse(JSON.stringify(currentArea));
            tempArea.fileUrlToDelete = photoUrls[idx].replace(`${process.env.REACT_APP_API}/`, '');
            const resp = (await request('database', 'removeImageFromArea', [tempArea]));
            if (resp.ok) {
                for (let i = 0; i < tempArea.AreaDetails.length; i ++) {
                    if (tempArea.AreaDetails[i].FieldEntryID === FIELD_ENTRY_ID_PHOTO_FILENAMES) {
                        tempArea.AreaDetails[i].FieldValue = resp.data;
                        break;
                    }
                }
                setCurrentArea(tempArea);
            } else {
                console.log(resp.data);
                NotificationManager.error('Failed to delete photo on server');
                return;
            }
        }
        let uploadedPhotoUrls = JSON.parse(JSON.stringify(photoUrls));
        let urlToRevoke = uploadedPhotoUrls[idx];
        uploadedPhotoUrls.splice(idx, 1);
        setPhotoUrls(uploadedPhotoUrls);

        let uploadedPhotoFiles = [...photoFiles];
        uploadedPhotoFiles.splice(idx, 1);
        setPhotoFiles(uploadedPhotoFiles);

        let revokeUrls = JSON.parse(JSON.stringify(urlsToRevoke));
        revokeUrls.push(urlToRevoke);
        setUrlsToRevoke(revokeUrls);

        setShowDeletePhotoModal(false);
    }

    useEffect(() => {
        return () => {
            for (let i = 0; i < photoUrls.length; i ++) {
                URL.revokeObjectURL(photoUrls[i]);
            }
            setPhotoUrls([]);
            setPhotoFiles([]);
        }
    }, []);

    useEffect(() => {
        for (let i = 0; i < urlsToRevoke.length; i ++) {
            URL.revokeObjectURL(urlsToRevoke[i]);
        }
        setUrlsToRevoke([]);
    }, [photoUrls]);

    useEffect(() => {
        if (area) {
            let tempArea = JSON.parse(JSON.stringify(area));
            if (currentArea.PropertyID) {
                tempArea.PropertyID = currentArea.PropertyID;
            }
            setCurrentArea(tempArea);
            
            try {
                let urlField = area.AreaDetails.find((d) => d.FieldEntryID === FIELD_ENTRY_ID_PHOTO_FILENAMES).FieldValue;
                if (urlField.length > 0) {
                    let photoFileNamesOnServer = urlField.split(",");
                    if (photoFileNamesOnServer.length > 0) {
                        let urls = [];
                        let files = [];
                        for (let i = 0; i < photoFileNamesOnServer.length; i ++) {
                            urls.push(`${process.env.REACT_APP_API}/${photoFileNamesOnServer[i]}.jpg`);
                            files.push(null);
                        }
                        setPhotoUrls(urls);
                        setPhotoFiles(files);
                    }
                }
            } catch (err) {
                console.log('No photos on server');
            }
        }
    }, [area]);

    let lastInspectedDate = new Date(Date.parse(getAreaFieldValue(FIELD_ENTRY_ID_LAST_INSPECTED)));
    let nextInspectedDate = new Date(Date.parse(getAreaFieldValue(FIELD_ENTRY_ID_NEXT_INSPECTION_DUE)));
    let lastInspectedDateText = isNaN(lastInspectedDate) ? getAreaFieldValue(FIELD_ENTRY_ID_LAST_INSPECTED) : getStringFromDate(lastInspectedDate);
    let nextInspectedDateText = isNaN(nextInspectedDate) ? getAreaFieldValue(FIELD_ENTRY_ID_NEXT_INSPECTION_DUE) : getStringFromDate(nextInspectedDate);

    return <StyledAddEditAreaEntryPage {...restProps}>
        <div style={{display: 'flex'}}>
            {user.Type && <PrimaryButton caption='Save' isLoading={isSaving} icon={faEdit} onClick={(e) => onSave()} style={{width: 86, height: 33}}></PrimaryButton>}
            {(user.Type && isEditMode) && <SecondaryButton caption='Delete area' isLoading={isDeleting} onClick={(e) => setShowDeleteAreaModal(true)} style={{width: 96, height: 33, marginLeft: 30}}></SecondaryButton>}
        </div>
        <StyledAddEditAreaEntryPageMobile>
            <div>{/* left side */}
                <FormInput label='Area name' disabled={!user.Type} value={getAreaFieldValue(FIELD_ENTRY_ID_AREA_NAME)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_AREA_NAME, txt)} inputStyle={{width: '100%'}}></FormInput>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: 15, marginTop: 20}}>
                    <FormInput label='Floor level' disabled={!user.Type} value={getAreaFieldValue(FIELD_ENTRY_ID_FLOOR_LEVEL)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_FLOOR_LEVEL, txt)} inputStyle={{width: '100%'}}></FormInput>
                    <FormInput label='Room' disabled={!user.Type} value={getAreaFieldValue(FIELD_ENTRY_ID_ROOM_NUMBER)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_ROOM_NUMBER, txt)} inputStyle={{width: '100%'}}></FormInput>
                </div>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Room name' value={getAreaFieldValue(FIELD_ENTRY_ID_ROOM_NAME)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_ROOM_NAME, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 35}} disabled={!user.Type} label='Item description' value={getAreaFieldValue(FIELD_ENTRY_ID_ITEM_DESCRIPTION)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_ITEM_DESCRIPTION, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Extent' value={getAreaFieldValue(FIELD_ENTRY_ID_EXTENT)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_EXTENT, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Location' value={getAreaFieldValue(FIELD_ENTRY_ID_LOCATION)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_LOCATION, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Condition/Surface treatement' value={getAreaFieldValue(FIELD_ENTRY_ID_CONDITION)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_CONDITION, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Sample number' value={getAreaFieldValue(FIELD_ENTRY_ID_SAMPLE_NUMBER)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_SAMPLE_NUMBER, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Asbestos type' value={getAreaFieldValue(FIELD_ENTRY_ID_ASBESTOS_TYPE)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_ASBESTOS_TYPE, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Material assessment' value={getAreaFieldValue(FIELD_ENTRY_ID_MATERIAL_ASSESSMENT)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_MATERIAL_ASSESSMENT, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormTextArea style={{marginTop: 20}} disabled={!user.Type} label='Recommendation' value={getAreaFieldValue(FIELD_ENTRY_ID_RECOMMENDATION)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_RECOMMENDATION, txt)} inputStyle={{width: '100%', height: 100}}></FormTextArea>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Within timescale' value={getAreaFieldValue(FIELD_ENTRY_ID_WITHIN_TIMESCALE)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_WITHIN_TIMESCALE, txt)} inputStyle={{width: '100%'}}></FormInput>
                <FormInput style={{marginTop: 20}} disabled={!user.Type} label='Photo/Diag reference' value={getAreaFieldValue(FIELD_ENTRY_ID_PHOTO_DIAG_REFERENCE)} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_PHOTO_DIAG_REFERENCE, txt)} inputStyle={{width: '100%'}}></FormInput>
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: 15, marginTop: 20}}>
                    <FormInput disabled={!user.Type} label='Last inspected' value={lastInspectedDateText} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_LAST_INSPECTED, txt)} inputStyle={{width: '100%'}}></FormInput>
                    <FormInput disabled={!user.Type} label='Next inspection due' value={nextInspectedDateText} onChange={(txt) => setAreaFieldValue(FIELD_ENTRY_ID_NEXT_INSPECTION_DUE, txt)} inputStyle={{width: '100%'}}></FormInput>
                </div>
            </div>
            <div>{/* right side */}
                <Text style={{fontSize: 13, marginTop: 20}}>Area condition</Text>
                <div style={{display: 'grid', gridTemplateColumns: '25px 25px 25px', gridColumnGap: 10, marginTop: 1}}>
                    <ColorCheckBox disabled={!user.Type} checked={getAreaFieldValue(FIELD_ENTRY_ID_AREA_COLOR) === '1' ? true : false} color='#3DC83D' onCheck={(val) => {if (user.Type) setAreaFieldValue(FIELD_ENTRY_ID_AREA_COLOR, '1');}}></ColorCheckBox>
                    <ColorCheckBox disabled={!user.Type} checked={getAreaFieldValue(FIELD_ENTRY_ID_AREA_COLOR) === '2' ? true : false} color='#F3B41E' onCheck={(val) => {if (user.Type) setAreaFieldValue(FIELD_ENTRY_ID_AREA_COLOR, '2');}}></ColorCheckBox>
                    <ColorCheckBox disabled={!user.Type} checked={getAreaFieldValue(FIELD_ENTRY_ID_AREA_COLOR) === '3' ? true : false} color='#FF1616' onCheck={(val) => {if (user.Type) setAreaFieldValue(FIELD_ENTRY_ID_AREA_COLOR, '3');}}></ColorCheckBox>
                </div>
                <Text style={{fontSize: 13, marginTop: 43}}>Photos</Text>
                {user.Type && <SecondaryButton caption='Upload file' icon={faFileUpload} onClick={(e) => {uploadFile.current.click(e);}} style={{width: 140, height: 40, marginTop: 5, fontSize: 13}}></SecondaryButton>}
                <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: 20, gridRowGap: 20, marginTop: 20, width: '100%'}}>
                    {photoUrls.map((photoUrl, idx) => {
                        return <CustomImage disabled={!user.Type} key={idx} src={photoUrl} style={{width: '100%'}} onZoom={() => zoomImage(idx)} onRemove={() => {setDeletePhotoIdx(idx); setShowDeletePhotoModal(true);}}/>
                    })}
                </div>
            </div>
        </StyledAddEditAreaEntryPageMobile>

        <input type="file" id='file' multiple={true} ref={uploadFile} onChange={(e) => onUploadFile(e)} style={{display: 'none'}} accept=".jpg*" />

        {showDeleteAreaModal && <ConfirmationModal title='Delete area' text='Are you sure you want to delete this area?' yesBtnText='Delete' onConfirm={(e) => onDeleteArea()} onCancel={(e) => setShowDeleteAreaModal(false)}/>}
        {showDeletePhotoModal && <ConfirmationModal title='Delete photo' text='Are you sure you want to delete this photo?' yesBtnText='Delete' onConfirm={(e) => removeImage(deletePhotoIdx)} onCancel={(e) => setShowDeletePhotoModal(false)}/>}
        {showFullScreenImage && <FullScreenImage url={fullScreenImageUrl} onClose={(e) => setShowFullScreenImage(false)}/>}
    </StyledAddEditAreaEntryPage>
}

export default AddEditAreaEntryPage;