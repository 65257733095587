import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../SingleComponents/PrimaryButton/PrimaryButton';
import ThirdButton from '../../SingleComponents/ThirdButton/ThirdButton';
import Text from '../../SingleComponents/Text/text';
import UpdatePropertyPage from '../../../Pages/UpdatePropertyPage/updatepropertypage';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import request from '../../../api/request';
import NotificationManager from 'react-notifications/lib/NotificationManager';

const StyledPropertyList = styled.div`
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    .react-datepicker-wrapper {
        width: 160px;
    }
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 13px 15px 13px 50px;
    border: solid 1px ${props => props.theme.color.borderGray};
    border-radius: 5px 5px 0px 0px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' width='13' height='13' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
    background-position: left 20px top 50%;
    box-sizing: border-box;

    :focus {
        outline: none !important;
        border:1px solid ${(props) => props.theme.color.secondary};
    }
`;

const PropertiesTable = styled.div`
    overflow-y: auto;
    height: 100%;

    div:nth-child(2n+1) {
        background: #FAFAFB 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:nth-child(2n) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #DFDFDF;
        border-bottom: none;
    }

    div:last-child {
        border: 1px solid #DFDFDF;
    }
`;

const PropertyItem = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    cursor: pointer;
    user-select: none;

    padding: 10px 15px;
    box-sizing: border-box;

    ${({ selected }) => selected === true && `
        background: #DFDFDF 0% 0% no-repeat padding-box !important;
    `}

    @media (max-width: 820px) {
        flex-direction: column;
        -moz-box-align: start;
        align-items: start;
      }
`;

function PropertyList(props) {

    const { user, company, properties, onDeletedProperty, onUpdatedProperty, onViewPropertyAreas, ...restProps } = props;

    const [searchText, setSearchText] = useState('');
    const [selectedPropertyIndex, setSelectedPropertyIndex] = useState(-1);
    const [isPropertyEditMode, setIsPropertyEditMode] = useState(false);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [isPropertyChecked, setIsPropertyChecked] = useState([]);
    const [nextInspectionDate, setNextInspectionDate] = useState(new Date());
    const [isUpdatingNextInspectionDate, setIsUpdatingNextInspectionDate] = useState(false);

    const onViewPropertyDetails = () => {
        setIsPropertyEditMode(true);
    }

    const getStringFromDate = (date) => {
        var mm = date.getMonth() + 1; // getMonth() is zero-based
        var dd = date.getDate();

        return [(dd>9 ? '' : '0') + dd,
                (mm>9 ? '' : '0') + mm,
                date.getFullYear()
                ].join('-');
    }

    const getPropertyChecked = (property) => {
        let idx = properties.indexOf(property);
        if (idx >= 0 && isPropertyChecked[idx] !== undefined) {
            return isPropertyChecked[idx];
        } else {
            return false;
        }
    }

    const setPropertyChecked = (property, checked) => {
        let idx = properties.indexOf(property);
        let propertyChecked = [...isPropertyChecked];
        if (idx >= 0) {
            propertyChecked[idx] = checked;
        }
        setIsPropertyChecked(propertyChecked);
    }

    const isAnyPropertyChecked = () => {
        for (let i = 0; i < isPropertyChecked.length; i ++) {
            if (isPropertyChecked[i] === true) {
                return true;
            }
        }
        return false;
    }

    const onUpdateInspectionDate = async () => {
        setIsUpdatingNextInspectionDate(true);
        try {
            let propertyData = {};
            console.log(nextInspectionDate, getStringFromDate(nextInspectionDate))
            propertyData.nextInspectionDate = getStringFromDate(nextInspectionDate);
            propertyData.propertyIDs = [];
            for (let i = 0; i < properties.length; i ++) {
                if (isPropertyChecked[i]) {
                    propertyData.propertyIDs.push(properties[i].PropertyID);
                }
            }

            const resp = (await request('database', 'updateInspectionDate', [propertyData]));
            if (resp.ok) {
                NotificationManager.success('Successfully updated inspection date');
            }
            setIsUpdatingNextInspectionDate(false);
        } catch (e) {
            NotificationManager.error("Failed to update inspection date");
            setIsUpdatingNextInspectionDate(false);
        }
    }

    useEffect(() => {
        setFilteredProperties(properties.filter((p) => {
            return p.PropertyName.toLowerCase().includes(searchText);
        }));
    }, [searchText, properties]);

    useEffect(() => {
        if (isPropertyChecked.length !== properties.length) {
            let propertyChecked = [];
            for (let i = 0; i < properties.length; i ++) {
                propertyChecked.push(false);
            }
            setIsPropertyChecked(propertyChecked);
        }
    }, [properties]);

    return <StyledPropertyList {...restProps}>
        {isAnyPropertyChecked() && <div style={{display: 'flex', gap: 30, marginBottom: 10}}>
            <DatePicker dateFormat="dd/MM/yyyy" inputFormat="dd/MM/yyyy" selected={nextInspectionDate} onChange={(date) => setNextInspectionDate(date)} />
            <PrimaryButton caption='Update inspection date' isLoading={isUpdatingNextInspectionDate} onClick={(e) => {onUpdateInspectionDate()}} style={{marginTop: 'auto', marginBottom: 5, fontSize: 11}}></PrimaryButton>
        </div>}
        <SearchInput placeholder='Search for a property' onChange={(e) => setSearchText(e.target.value)}/>
        <PropertiesTable>
            {properties.filter((p) => {
                return p.PropertyName.toLowerCase().includes(searchText);
            }).map((p, idx) => {
                let pChecked = getPropertyChecked(p);
                return <PropertyItem key={idx} selected={selectedPropertyIndex===idx} onClick={(e) => setSelectedPropertyIndex(idx)}>
                    <input type='checkbox' checked={pChecked} onChange={(e) => {setPropertyChecked(p, !pChecked)}}/>
                    <Text style={{fontSize: 13, fontWeight: selectedPropertyIndex===idx ? 600 : 400}}>{p.PropertyName}</Text>
                    {(user.Type===true && selectedPropertyIndex===idx) && <ThirdButton caption='Edit property' onClick={(e) => onViewPropertyDetails(idx)} style={{marginLeft: 'auto', fontSize: 11}}></ThirdButton>}
                    {(selectedPropertyIndex===idx) && <PrimaryButton caption='View areas' onClick={(e) => onViewPropertyAreas(filteredProperties[selectedPropertyIndex])} style={{marginLeft: user.Type ? 0 : 'auto', marginRight: 10, fontSize: 11}}></PrimaryButton>}
                </PropertyItem>
            })}
        </PropertiesTable>
        {isPropertyEditMode && <UpdatePropertyPage company={company} property={filteredProperties[selectedPropertyIndex]} onClose={() => setIsPropertyEditMode(false)} onUpdatedProperty={onUpdatedProperty} onDeletedProperty={onDeletedProperty}/>}
    </StyledPropertyList>
}

export default PropertyList;