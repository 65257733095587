import styled from 'styled-components';
import { useState, useEffect } from "react";
import Logo from '../../components/SingleComponents/Logo/logo';
import Text from '../../components/SingleComponents/Text/text';
import FormInput from '../../components/CombinedComponents/FormInput/FormInput';
import FormPasswordInput from '../../components/CombinedComponents/FormPasswordInput/FormPasswordInput';
import FormCheckBox from '../../components/CombinedComponents/FormCheckBox/FormCheckBox';
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import request from '../../api/request';
import { useNavigate } from 'react-router-dom';

const StyledLoginPage = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.theme.color.backgroundColor};
`;

const StyledWhiteBoard = styled.div`
    width: 380px;
    min-height: 356px;
    padding: 40px;
    margin-top: 40px;
    background-color: white;
    box-sizing: border-box;
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 12px;
`;

function LoginPage(props) {

    const navigate = useNavigate();
    
    const {setLogin} = props;

    const SERVER_STATUS_CONNECTING = 0;
    const SERVER_STATUS_CONNECTED = 1;
    const SERVER_STATUS_NODATABASE = 2;
    const SERVER_STATUS_CONNECTION_FAILED = 3;
    const SERVER_STATUS_LOGIN_FAILED = 4;

    let loadedPage = false;

    const [serverStatus, setServerStatus] = useState(SERVER_STATUS_CONNECTING);
    const [serverStatusMessage, setServerStatusMessage] = useState("");
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [needsValidation, setNeedsValidation] = useState(false);
    const [isRememberMe, setIsRememberMe] = useState(false);

    const onChangeUsername = (txt) => {
        setUserName(txt);
        setNeedsValidation(false);
    }

    const onChangePassword = (txt) => {
        setPassword(txt);
        setNeedsValidation(false);
    }

    const onLogin = async () => {
        if (username === '' || password === '') {
            setNeedsValidation(true);
        } else {
            let loginResp = (await request('auth', 'login', [username, password]));
            if (loginResp.status === 200) {
                setServerStatus(SERVER_STATUS_CONNECTED);
                setLogin(loginResp.data);
            } else if (loginResp.status === 401) {
                setServerStatus(SERVER_STATUS_LOGIN_FAILED);
                setServerStatusMessage("Username or password is incorrect");
            } else {
                setServerStatus(SERVER_STATUS_LOGIN_FAILED);
                setServerStatusMessage("Cannot connect to server");
            }
        }
    };

    useEffect(() => {
        if (!loadedPage) {
            loadedPage = true;
        }
    }, []);

    return <StyledLoginPage>
        <div style={{margin: 'auto'}}>
            <Logo style={{margin: 'auto', fontSize: 30}}></Logo>
            <StyledWhiteBoard>
                <Text style={{fontSize: 20, fontWeight: 600}}>Login to client area</Text>
                <FormInput label='Username' style={{marginTop: 37}} value={username} onChange={onChangeUsername} onPressEnter={onLogin} inputStyle={{width: 300}}></FormInput>
                {(needsValidation === true && username === '') && <ErrorMessage>Please enter username</ErrorMessage>}
                <FormPasswordInput label='Password' style={{marginTop: 17}} value={password} onChange={onChangePassword} onPressEnter={onLogin} inputStyle={{width: 300}}></FormPasswordInput>
                {(needsValidation === true && password === '') && <ErrorMessage>Please enter password</ErrorMessage>}
                <FormCheckBox label='Remember me' checked={isRememberMe} onCheck={(val) => setIsRememberMe(val)} style={{marginTop: 15}}></FormCheckBox>
                {(serverStatus === SERVER_STATUS_LOGIN_FAILED) && <div><ErrorMessage>{serverStatusMessage}</ErrorMessage></div>}
                <PrimaryButton caption='Login' onClick={onLogin} style={{width: 62, float: 'right'}}></PrimaryButton>
            </StyledWhiteBoard>
        </div>
    </StyledLoginPage>
}

export default LoginPage;