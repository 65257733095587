import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import Text from '../../components/SingleComponents/Text/text';
import FormInput from "../../components/CombinedComponents/FormInput/FormInput";
import CloseButton from '../../components/SingleComponents/CloseButton/closebutton';
import ConfirmationModal from '../../components/CombinedComponents/ConfirmationModal/confirmationmodal';
import { NotificationManager } from 'react-notifications';
import request from '../../api/request';

const StyledUpdatePropertyPage = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const AddCompanyModal = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 5px;
    width: 513px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
    height: fit-content;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;

    @media (max-width: 820px) {
        width: inherit;
        margin: 20px;
        padding: 20px;
      }
`;

const ErrorMessage = styled.span`
    color: red;
    font-size: 12px;
`;

function UpdatePropertyPage(props) {

    let loadedPage = false;
    const { company, property, onClose, onDeletedProperty, onUpdatedProperty, ...restProps } = props;

    const [needsValidation, setNeedsValidation] = useState(false);
    const [propertyName, setPropertyName] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const onChangePropertyName = (txt) => {
        setPropertyName(txt);
        setNeedsValidation(false);
    }

    const onUpdateProperty = async () => {
        if (propertyName === '') {
            setNeedsValidation(true);
        } else {
            setIsSaving(true);
            let propertyData = {};
            propertyData.PropertyID = property.PropertyID;
            propertyData.PropertyName = propertyName;

            const resp = (await request('database', 'updateProperty', [propertyData]));
            if (resp.ok) {
                NotificationManager.success('Successfully updated a property');
                onUpdatedProperty(property.PropertyID, propertyName);

                onClose();
            } else
            {
                console.log(resp.data);
                NotificationManager.error(resp.data);
            }
            setIsSaving(false);
        }
    };

    const onDeleteProperty = async () => {
        setShowModal(false);
        setIsDeleting(true);
        let propertyData = {};
        propertyData.PropertyID = property.PropertyID;

        const resp = (await request('database', 'deleteProperty', [propertyData]));
        if (resp.ok) {
            NotificationManager.success('Successfully deleted a property');
            onDeletedProperty(property.PropertyID);

            onClose();
        } else
        {
            console.log(resp.data);
            NotificationManager.error(resp.data);
        }
        setIsDeleting(false);
    };

    useEffect(() => {
        if (!loadedPage) {
            loadedPage = true;
            setPropertyName(property.PropertyName);
        }
    }, []);

    return <StyledUpdatePropertyPage {...restProps} onClick={onClose}>
        <AddCompanyModal onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 20, fontWeight: 600}}>Edit property</Text>
                <CloseButton onClick={onClose} style={{marginLeft: 'auto'}}/>
            </div>
            <Text style={{fontSize: 13}}>Editing property for company</Text>
            <Text style={{fontSize: 13, fontWeight: 600}}>{company.CompanyName}</Text>
            <FormInput label='Property name' style={{marginTop: 10}} value={propertyName} onChange={onChangePropertyName}></FormInput>
            {(needsValidation === true && propertyName === '') && <ErrorMessage>Please enter property name</ErrorMessage>}
            <div style={{marginTop: 40, display: 'flex'}}>
                <SecondaryButton caption='Delete' isLoading={isDeleting} onClick={(e) => setShowModal(true)} style={{width: 70, height: 33}}></SecondaryButton>
                <SecondaryButton caption='Cancel' onClick={onClose} style={{width: 70, height: 33, marginLeft: 'auto'}}></SecondaryButton>
                <PrimaryButton caption='Update' isLoading={isSaving} onClick={(e) => onUpdateProperty()} style={{width: 70, height: 33, marginLeft: 10}}></PrimaryButton>
            </div>
        </AddCompanyModal>
        {showModal && <ConfirmationModal title='Delete property' text='Are you sure you want to delete this property?' yesBtnText='Delete' onConfirm={(e) => onDeleteProperty()} onCancel={(e) => setShowModal(false)}/>}
    </StyledUpdatePropertyPage>
}

export default UpdatePropertyPage;