import styled from 'styled-components';
import { useState, useEffect } from "react";
import Text from '../../components/SingleComponents/Text/text';
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import request from '../../api/request';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import CompanyList from '../../components/CombinedComponents/CompanyList/companylist';
import PropertyList from '../../components/CombinedComponents/PropertyList/propertylist';
import AreaList from '../../components/CombinedComponents/AreaList/arealist';
import { NotificationManager, NotificationContainer } from 'react-notifications';
import AddCompanyPage from '../AddCompanyPage/addcompanypage';
import AddPropertyPage from '../AddPropertyPage/addpropertypage';
import AddInspectionPage from '../AddInspectionPage/addinspectionpage';
import AddEditAreaEntryPage from '../AddEditAreaEntryPage/addeditareaentrypage';
import BackButton from '../../components/SingleComponents/BackButton/backbutton';
import 'react-notifications/lib/notifications.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const StyledCompaniesPageParent = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    align-items: center;

    display: flex;
    flex-direction: column;
`;

const StyledCompaniesPage = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 40px;
    align-items: center;

    display: flex;
    flex-direction: column;

    @media (max-width: 820px) {
        padding: 20px;
        top: 88px;
        position: relative;
      }
`;

const StyledCompaniesPageMobile = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;

    @media (max-width: 820px) {
        flex-direction: column;
        align-items: start;
      }
`;

const PrimaryColorText = styled.span`
    color: ${(props) => props.theme.color.primary};
    font-weight: 600;
    margin-right: auto;
    font-size: 20px;
`;

function CompaniesPage(props) {

    let loadedPage = false;
    const navigate = useNavigate();
    const location = useLocation();
    const { routeCompanyID, routePropertyID, routeAreaID } = useParams();

    const { user, ...restProps } = props;

    const PAGE_NONE = -1;
    const PAGE_COMPANIES = 0;
    const PAGE_PROPERTIES = 1;
    const PAGE_AREAS = 2;
    const PAGE_AREA_ENTRY = 3;

    const AREA_ENTRY_PAGE_MODE_NONE = 0;
    const AREA_ENTRY_PAGE_MODE_ADD = 1;
    const AREA_ENTRY_PAGE_MODE_EDIT = 2;

    const [currentPage, setCurrentPage] = useState(PAGE_NONE);
    const [isAddCompanyMode, setIsAddCompanyMode] = useState(false);
    const [isAddPropertyMode, setIsAddPropertyMode] = useState(false);
    const [isAddInspectionMode, setIsAddInspectionMode] = useState(false);

    const [companyList, setCompanyList] = useState([]);
    const [propertyList, setPropertyList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedArea, setSelectedArea] = useState(null);

    const [modeOnAddEntryPage, setModeOnAddEntryPage] = useState(AREA_ENTRY_PAGE_MODE_NONE);

    const onDeletedCompany = (companyID) => {
        let companies = [...companyList];

        for (let i = 0; i < companies.length; i ++) {
            if (companies[i].CompanyID === companyID) {
                companies.splice(i, 1);
                break;
            }
        }

        setCompanyList(companies);
    }

    const onUpdatedCompany = (companyID, companyName, companyStatus) => {
        let companies = [...companyList];

        for (let i = 0; i < companies.length; i ++) {
            if (companies[i].CompanyID === companyID) {
                companies[i].CompanyName = companyName;
                companies[i].Status = companyStatus;
                break;
            }
        }

        setCompanyList(companies);
    }

    const onAddedCompany = (companyID, companyName, companyStatus) => {
        let companies = [...companyList];

        companies.unshift({
            CompanyID: companyID,
            CompanyName: companyName,
            Status: companyStatus
        });

        setCompanyList(companies);
        setIsAddCompanyMode(false);
    }

    const onViewCompanyProperties = async (company) => {
        try {
            let properties = [];

            let companyData = {};
            companyData.CompanyID = company.CompanyID;

            let resp = (await request('database', 'fetchPropertiesOfCompany', [companyData])).data;
            resp.map((p) => {
                properties.push(p);
            });
            properties.sort((a, b) => a.PropertyName.localeCompare(b.PropertyName));
            setPropertyList(properties);
            setSelectedCompany(company);
            setCurrentPage(PAGE_PROPERTIES);

            navigate(`/companies/${company.CompanyID}/properties`);
        } catch (e) {
            NotificationManager.error("Failed to fetch property list");
        }
    }

    const onDeletedProperty = (propertyID) => {
        let properties = [...propertyList];

        for (let i = 0; i < properties.length; i ++) {
            if (properties[i].PropertyID === propertyID) {
                properties.splice(i, 1);
                break;
            }
        }

        setPropertyList(properties);
    }

    const onUpdatedProperty = (propertyID, propertyName) => {
        let properties = [...propertyList];

        for (let i = 0; i < properties.length; i ++) {
            if (properties[i].PropertyID === propertyID) {
                properties[i].PropertyName = propertyName;
                break;
            }
        }

        setPropertyList(properties);
    }

    const onAddedProperty = (propertyID, propertyName) => {
        let properties = [...propertyList];

        properties.unshift({
            PropertyID: propertyID,
            PropertyName: propertyName,
        });

        setPropertyList(properties);
        setIsAddPropertyMode(false);
    }

    const onViewPropertyAreas = async (property) => {
        try {
            let areas = [];

            let propertyData = {};
            propertyData.PropertyID = property.PropertyID;
            
            let resp = (await request('database', 'fetchAreasOfProperty', [propertyData])).data;
            resp.map((p) => {
                areas.push(p);
            });
            areas.sort((a, b) => a.AreaName.localeCompare(b.AreaName));
            setAreaList(areas);
            setSelectedProperty(property);
            setCurrentPage(PAGE_AREAS);

            navigate(`/companies/${selectedCompany.CompanyID}/properties/${property.PropertyID}/areas`);
        } catch (e) {
            NotificationManager.error("Failed to fetch area list");
        }
    }

    const onDeletedArea = (areaID) => {
        let areas = JSON.parse(JSON.stringify(areaList));

        for (let i = 0; i < areas.length; i ++) {
            if (areas[i].AreaID === areaID) {
                areas.splice(i, 1);
                break;
            }
        }

        setAreaList(areas);
    }

    const onUpdatedArea = (areaID, areaName, areaDetails) => {
        let areas = JSON.parse(JSON.stringify(areaList));

        for (let i = 0; i < areas.length; i ++) {
            if (areas[i].AreaID === areaID) {
                areas[i].AreaName = areaName;
                areas[i].AreaDetails = areaDetails;
                break;
            }
        }

        setAreaList(areas);
    }

    const onAddedArea = (areaID, areaName, areaDetails) => {
        let areas = JSON.parse(JSON.stringify(areaList));

        if (areas.length === 0) {
            areas.push({
                AreaID: areaID,
                AreaName: areaName,
                AreaDetails: areaDetails,
            });
        } else {
            let lastInspectionPos = 0;
            for (let i = 0; i < areas.length; i ++) {
                if (areas[i].AreaDetails.find((d) => d.FieldEntryID === null)) {
                    lastInspectionPos = i;
                }
            }
            areas.splice(lastInspectionPos + 1, 0, {
                AreaID: areaID,
                AreaName: areaName,
                AreaDetails: areaDetails,
            })
        }

        setAreaList(areas);
        setIsAddInspectionMode(false);
    }

    const onViewAreaDetails = async (area) => {
        setSelectedArea(area);
        if (area) {
            setModeOnAddEntryPage(AREA_ENTRY_PAGE_MODE_EDIT);
            navigate(`/companies/${selectedCompany.CompanyID}/properties/${selectedProperty.PropertyID}/areas/${area.AreaID}/editArea`);
        } else {
            setModeOnAddEntryPage(AREA_ENTRY_PAGE_MODE_ADD);
            navigate(`/companies/${selectedCompany.CompanyID}/properties/${selectedProperty.PropertyID}/areas/addArea`);
        } 
        setCurrentPage(PAGE_AREA_ENTRY);
    }

    const goBackToCompaniesPage = () => {
        setCurrentPage(PAGE_COMPANIES);
        navigate('/companies');
    }

    const goBackToPropertiesPage = () => {
        setCurrentPage(PAGE_PROPERTIES);
        navigate(`/companies/${routeCompanyID}/properties`);
    }

    const goBackToAreasPage = () => {
        setCurrentPage(PAGE_AREAS);
        navigate(`/companies/${routeCompanyID}/properties/${routePropertyID}/areas`);
    }

    useEffect(() => {
        async function preLoad() {
            try {
                let companies = [];
                let properties = [];
                let areas = [];
                if (companyList.length === 0) {
                    console.log('load companies');
                    let userData = {};
                    userData.UserID = user.UserID;
                    userData.Username = user.Username;
                    userData.Type = user.Type;
                    userData.Status = user.Status;
                    let resp = (await request('database', 'fetchCompanyList', [userData])).data;
                    resp.map((c) => {
                        companies.push(c);
                    });
                    companies.sort((a, b) => a.CompanyName.localeCompare(b.CompanyName));
                    setCompanyList(companies);
                } else {
                    companies = companyList;
                }

                if (location.pathname.includes('/properties')) {
                    if (propertyList.length === 0) {
                        console.log('load properties');
                        properties = [];
            
                        let companyData = {};
                        companyData.CompanyID = parseInt(routeCompanyID);
            
                        let resp = (await request('database', 'fetchPropertiesOfCompany', [companyData])).data;
                        resp.map((p) => {
                            properties.push(p);
                        });
                        properties.sort((a, b) => a.PropertyName.localeCompare(b.PropertyName));
                        setPropertyList(properties);
                    } else {
                        properties = propertyList;
                    }
                    let company = companies.find((c) => c.CompanyID === parseInt(routeCompanyID));
                    setSelectedCompany(company);

                    if (location.pathname.includes('/areas')) {
                        if (areaList.length === 0) {
                            console.log('load areas');
                            areas = [];

                            let propertyData = {};
                            propertyData.PropertyID = parseInt(routePropertyID);
                            
                            let resp = (await request('database', 'fetchAreasOfProperty', [propertyData])).data;
                            resp.map((p) => {
                                areas.push(p);
                            });
                            areas.sort((a, b) => a.AreaName.localeCompare(b.AreaName));
                            setAreaList(areas);
                        } else {
                            areas = areaList;
                        }
                        let property = properties.find((p) => p.PropertyID === parseInt(routePropertyID));
                        setSelectedProperty(property);

                        if (location.pathname.includes('/editArea')) {
                            let area = areas.find((a) => a.AreaID === parseInt(routeAreaID));
                            setSelectedArea(area);
                            setCurrentPage(PAGE_AREA_ENTRY);
                            setModeOnAddEntryPage(AREA_ENTRY_PAGE_MODE_EDIT);
                        } else if (location.pathname.includes('/addArea')) {
                            setSelectedArea(null);
                            setCurrentPage(PAGE_AREA_ENTRY);
                            setModeOnAddEntryPage(AREA_ENTRY_PAGE_MODE_ADD);
                        } else {
                            setCurrentPage(PAGE_AREAS);
                        }
                    } else {
                        setCurrentPage(PAGE_PROPERTIES);
                    }
                } else {
                    setCurrentPage(PAGE_COMPANIES)
                }
            } catch (e) {
                NotificationManager.error("Failed to fetch company list");
            }
        }

        preLoad();
    }, [location]);

    return <StyledCompaniesPageParent>
        {currentPage === PAGE_COMPANIES && <StyledCompaniesPage>


            <StyledCompaniesPageMobile>
            <div>
                <h1>List of companies</h1>
            </div>
            <div>
                {user.Type && <PrimaryButton icon={faEdit} caption='Add new company' onClick={() => setIsAddCompanyMode(true)} style={{width: 150, marginLeft: 'auto'}}></PrimaryButton>}
            </div>
            </StyledCompaniesPageMobile>


            <CompanyList style={{marginTop: 20}} companies={companyList} user={user} onUpdatedCompany={onUpdatedCompany} onDeletedCompany={onDeletedCompany} onViewCompanyProperties={onViewCompanyProperties}></CompanyList>
            {isAddCompanyMode && <AddCompanyPage user={user} companies={companyList} onAddedCompany={onAddedCompany} onClose={() => setIsAddCompanyMode(false)}/>}
        </StyledCompaniesPage>}
        {currentPage === PAGE_PROPERTIES && <StyledCompaniesPage>
            <div style={{display: 'flex', width: '100%', alignItems: 'center', marginBottom: 10}}>
                <BackButton onClick={(e) => goBackToCompaniesPage()}/>
                <Text style={{fontSize: 14, fontWeight: 600, marginBottom: 1, marginLeft: 12, cursor: 'pointer', useSelect: 'none'}} onClick={(e) => goBackToCompaniesPage()}>Back to companies</Text>
            </div>


            <StyledCompaniesPageMobile>
            <div>
                <h1>List of properties for <PrimaryColorText style={{fontSize: 20, fontWeight: 600, marginTop: 10, display: 'block', clear: 'both'}}>{selectedCompany.CompanyName}</PrimaryColorText></h1>
            </div>
            <div>
                {user.Type && <PrimaryButton icon={faEdit} caption='Add new property' onClick={() => setIsAddPropertyMode(true)} style={{width: 161, marginLeft: 'auto'}}></PrimaryButton>}
            </div>
            </StyledCompaniesPageMobile>
            

            <PropertyList style={{marginTop: 20}} user={user} company={selectedCompany} properties={propertyList} onUpdatedProperty={onUpdatedProperty} onDeletedProperty={onDeletedProperty} onViewPropertyAreas={onViewPropertyAreas}></PropertyList>
            {isAddPropertyMode && <AddPropertyPage user={user} company={selectedCompany} properties={propertyList} onAddedProperty={onAddedProperty} onClose={() => setIsAddPropertyMode(false)}/>}
        </StyledCompaniesPage>}
        {currentPage === PAGE_AREAS && <StyledCompaniesPage>
            <div style={{display: 'flex', width: '100%', alignItems: 'center', marginBottom: 10}}>
                <BackButton onClick={(e) => goBackToPropertiesPage()}/>
                <Text style={{fontSize: 14, fontWeight: 600, marginBottom: 1, marginLeft: 12, cursor: 'pointer', useSelect: 'none'}} onClick={(e) => goBackToPropertiesPage()}>Back to properties for {selectedCompany.CompanyName}</Text>
            </div>


            <StyledCompaniesPageMobile>
            <div>
                <h1>List of areas for <PrimaryColorText style={{fontSize: 20, fontWeight: 600, marginTop: 10, display: 'block', clear: 'both'}}>{selectedProperty.PropertyName}</PrimaryColorText></h1>
            </div>
            <div>
                {user.Type && <SecondaryButton icon={faEdit} caption='Add new inspection' onClick={() => setIsAddInspectionMode(true)} style={{width: 160, marginLeft: 'auto'}}></SecondaryButton>}
                {user.Type && <PrimaryButton icon={faEdit} caption='Add new area' onClick={() => {onViewAreaDetails(null)}} style={{width: 130, marginLeft: 10}}></PrimaryButton>}
            </div>
            </StyledCompaniesPageMobile>

            <AreaList style={{marginTop: 20}} user={user} property={selectedProperty} areas={areaList} onUpdatedArea={onUpdatedArea} onDeletedArea={onDeletedArea} onViewAreaDetails={onViewAreaDetails}></AreaList>
            {isAddInspectionMode && <AddInspectionPage user={user} property={selectedProperty} areas={areaList} onAddedArea={onAddedArea} onClose={() => setIsAddInspectionMode(false)}/>}
        </StyledCompaniesPage>}
        {currentPage === PAGE_AREA_ENTRY && <StyledCompaniesPage>
            <div style={{display: 'flex', width: '100%', alignItems: 'center', marginBottom: 10}}>
                <BackButton onClick={(e) => goBackToAreasPage()}/>
                <Text style={{fontSize: 14, fontWeight: 600, marginBottom: 1, marginLeft: 12, cursor: 'pointer', useSelect: 'none'}} onClick={(e) => goBackToAreasPage()}>Back to areas for {selectedProperty.PropertyName}</Text>
            </div>
            <AddEditAreaEntryPage style={{marginTop: 40}} user={user} property={selectedProperty} areas={areaList} area={selectedArea} isEditMode={modeOnAddEntryPage===AREA_ENTRY_PAGE_MODE_EDIT} onAddedArea={onAddedArea} onUpdatedArea={onUpdatedArea} onDeletedArea={onDeletedArea} onClose={goBackToAreasPage}/>
        </StyledCompaniesPage>}
    </StyledCompaniesPageParent>
}

export default CompaniesPage;