import styled from 'styled-components';

const StyledLogo = styled.div`
    display: flex;
    width: min-content;
`;

const StyledTransText = styled.span`
    color: ${(props) => props.theme.color.primary};
    font-weight: 600;
`;

const StyledThermalText = styled.span`
    color: ${(props) => props.theme.color.secondary};
    font-weight: 600;
`;

function Logo(props) {

    const {fontSize, ...restProps} = props;

    return <StyledLogo {...restProps}>
        <StyledTransText style={{fontSize: fontSize}}>Trans</StyledTransText>
        <StyledThermalText style={{fontSize: fontSize}}>thermal</StyledThermalText>
    </StyledLogo>
}

export default Logo;