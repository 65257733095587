import styled from "styled-components";
import { keyframes } from "styled-components";

const StyledFormCheckBox = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    user-select: none;
`;

const LabelRegular = styled.div`
    font-size: 12px;
    margin-left: 8px;

    ${({ disabled }) => disabled === true && `
        color: #C7C7C7;
    `}
`;

const Input = styled.input`
    height: 0;
    width: 0;
    opacity: 0;
    z-index: -1;
`;

const Label = styled.label`
    margin-right: 10px;
    position: relative;
    display: inline-block;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`;

const rotate = keyframes`
    from {
        opacity: 0;
        transform: rotate(0deg);
    }
    to {
        opacity: 1;
        transform: rotate(45deg);
    }
`;

const Indicator = styled.div`
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    position: absolute;
    top: 3px;
    left: 0;
    
    border-radius: 5px;
    border: solid 1px ${props => props.checked ? props.theme.color.primary : props.theme.color.borderGray};

    ${Input}:not(:disabled):checked & {
        background: #d1d1d1;
    }

    &::after {
        content: "";
        position: absolute;
        display: none;
    }

    ${Input}:checked + &::after {
        display: block;
        top: 2px;
        left: 4.5px;
        width: 20%;
        height: 50%;
        border: solid ${(props) => props.theme.color.primary};
        border-width: 0 2px 2px 0;
        animation-name: ${rotate};
        animation-fill-mode: forwards;

        @media (max-width: 820px) {
            top: 2px;
          }
    }

    ${Input}:disabled:checked + &::after {
        display: block;
        top: 2px;
        left: 4.5px;
        width: 20%;
        height: 50%;
        border: solid ${(props) => props.theme.color.disabledgray};
        border-width: 0 2px 2px 0;
        animation-name: ${rotate};
        animation-fill-mode: forwards;

        @media (max-width: 820px) {
            top: 2px;
          }
    }

    &::disabled {
        cursor: not-allowed;
    }

    ${({ disabled }) => disabled === true && `
        border-color: #C7C7C7;
    `}
`;


function FormCheckBox(props) {

    const {label, checked, disabled, onCheck, ...restProps} = props;

    return (
        <StyledFormCheckBox {...restProps}>
            <Label disabled={disabled}>
            <Input
                type="checkbox"
                disabled={disabled}
                checked={checked}
                onChange={() => {onCheck(!checked)}}
            />
            <Indicator disabled={disabled} checked={checked}/>
            </Label>
            <LabelRegular disabled={disabled} onClick={() => {if (!disabled) onCheck(!checked)}}>{label}</LabelRegular>
        </StyledFormCheckBox>
    )
}

export default FormCheckBox;