export const defaultTheme = {
  color: {
    'primary': '#DB1D8F',
    'primaryhover': '#E642A4',
    'secondary': '#6096BA',
    'secondaryhover': '#C7C7C7',
    'borderGray': '#DFDFDF',
    'disabledBorder': '#C7C7C7',
    'disabledgray': '#C7C7C7',
    'mainTextColor': '#1A1919',
    'grayTextColor': '#959595',
    'backgroundColor': '#F8F8F8',
  },
  fonts: {
    'acuminPro': 'Segoe UI'
  },
}
