import styled from 'styled-components';
import { useState, useEffect } from "react";
import PrimaryButton from '../../components/SingleComponents/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import Text from '../../components/SingleComponents/Text/text';
import CloseButton from '../../components/SingleComponents/CloseButton/closebutton';
import { NotificationManager } from 'react-notifications';
import request from '../../api/request';

const StyledAddUserToCompanyPage = styled.div`
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #00000033 0% 0% no-repeat padding-box;
`;

const AddUserToCompanyModal = styled.div`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000036;
    border-radius: 5px;
    width: 366px;
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
    height: fit-content;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;

    @media (max-width: 820px) {
        width: inherit;
        margin: 20px;
        padding: 20px;
      }
`;

const UsersTable = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 170px;
    border: 1px solid ${props => props.theme.color.borderGray};
    border-radius: 5px;

    div:nth-child(2n) {
        background: #FAFAFB 0% 0% no-repeat padding-box;
        border-bottom: 1px solid #DFDFDF;
    }

    div:nth-child(2n+1) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-bottom: 1px solid #DFDFDF;
    }
`;

const UserItem = styled.div`
    width: 100%;
    height: 25px;
    display: flex;
    align-items: center;

    cursor: pointer;
    user-select: none;

    padding-left: 15px;
    box-sizing: border-box;

    ${({ selected }) => selected === true && `
        font-weight: 600;
        background: #DB1D8F 0% 0% no-repeat padding-box !important;

        p {
            color: white !important;
        }
    `}
`;

function AddUserToCompanyPage(props) {

    let loadedPage = false;
    const { selectedUsersForCompany, onAddUserToCompany, ...restProps } = props;

    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedUserIdx, setSelectedUserIdx] = useState(-1);

    useEffect(() => {
        if (!loadedPage) {
            loadedPage = true;
            async function preLoadAllUsers() {
                try {
                    let users = [];
                    let resp = (await request('database', 'fetchUserList')).data;
                    resp.map((user) => {
                        if (selectedUsersForCompany.length === 0 || !selectedUsersForCompany.find((u) => u.UserID === user.UserID)) {
                            users.push(user);
                        }
                    });
                    setAvailableUsers(users);
                } catch (e) {
                    NotificationManager.error("Failed to fetch available users");
                }
            }

            preLoadAllUsers();
        }
    }, []);

    return <StyledAddUserToCompanyPage {...restProps} onClick={(e) => onAddUserToCompany(e, null)}>
        <AddUserToCompanyModal onClick={(e) => e.stopPropagation()}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Text style={{fontSize: 20, fontWeight: 600}}>Add user</Text>
                <CloseButton onClick={(e) => onAddUserToCompany(e, null)} style={{marginLeft: 'auto'}}/>
            </div>
            <UsersTable>
                {availableUsers.map((u, idx) => {
                    return <UserItem key={idx} selected={selectedUserIdx===idx} onClick={(e) => setSelectedUserIdx(idx)}>
                        <Text style={{fontSize: 12}}>{u.Username}</Text>
                    </UserItem>
                })}
            </UsersTable>
            <div style={{display: 'flex'}}>
                <SecondaryButton caption='Cancel' onClick={(e) => onAddUserToCompany(e, null)} style={{width: 70, height: 33, marginLeft: 'auto'}}></SecondaryButton>
                <PrimaryButton caption='Add user' disabled={selectedUserIdx===-1} onClick={(e) => onAddUserToCompany(e, availableUsers[selectedUserIdx])} style={{width: 80, height: 33, marginLeft: 10}}></PrimaryButton>
            </div>
        </AddUserToCompanyModal>
    </StyledAddUserToCompanyPage>
}

export default AddUserToCompanyPage;