import styled from 'styled-components';
import Userspage from '../Userspage/userspage';
import CompaniesPage from '../CompaniesPage/companiespage';
import Logo from '../../components/SingleComponents/Logo/logo';
import SecondaryButton from '../../components/SingleComponents/SecondaryButton/SecondaryButton';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faUserFriends, faSignOut } from '@fortawesome/free-solid-svg-icons'

import { NotificationContainer } from 'react-notifications';

const StyledHomepage = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: ${(props) => props.theme.color.backgroundColor};

    @media (max-width: 820px) {
        flex-direction: column;
      }
`;

const WhiteSidebar = styled.div`
    position: fixed;
    width: 300px;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #CCDEE4;
    z-index: 99999;

    @media (max-width: 820px) {
        width: 100%;
        height: auto;
        display: flex;
        top: 49px;
        position: fixed;
        box-shadow: 0px 0px 10px #ddd;
      }
`;

const SideBarItem = styled.div`
    padding: 16px 20px;
    border: solid 0px ${(props) => props.selected===true ? props.theme.color.borderGray : props.theme.color.borderGray};
    box-shadow: inset 5px 0px 0px var(--accent);

    ${({ selected }) => selected === true && `
        background: #F2F2F2 0% 0% no-repeat padding-box;
        box-shadow: inset 5px 0px 0px #DB1D8F;

        @media (max-width: 820px) {
            box-shadow: inset 0px -2px 0px #DB1D8F;
            border: solid 0px;
          }
    `}

    color: ${props => props.selected===true ? props.theme.color.primary : props.theme.color.mainTextColor};

    align-items: center;
    display: flex;

    cursor: pointer;
    user-select: none;

    @media (max-width: 820px) {
        padding: 10px 13px;
      }
`;

const SideBarItemText = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-left: 13px;
`;

const WhiteNavbar = styled.div`
    position: fixed;
    width: 100%;
    padding: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #CCDEE4;
    display: flex;
    z-index: 99999;

    @media (max-width: 820px) {
        padding: 10px 20px;
      }
`;

const LogoDiv = styled.div`
    width: 100%;
    height: 68px;
    display: flex;
    background: #FFFFFF 0% 0% no-repeat padding-box;

    @media (max-width: 820px) {
        height: auto;
      }
`;

const PageContent = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 65px;
    padding-left: 300px;
    box-sizing: border-box;

    @media (max-width: 820px) {
        padding-top: 0;
        padding-left: 0;
      }
`;

function Homepage(props) {

    const { user } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const isOnCompanyPage = location.pathname.includes('/companies');
    const isOnUserPage = location.pathname.includes('/users');

    const onLogout = async () => {
        props.setLogout();
    };

    const onSelectCompanies = async () => {
        navigate('/companies');
    };

    const onSelectUsers = async () => {
        navigate('/users');
    };
    return <StyledHomepage>
        <WhiteNavbar>
            <span style={{marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto', fontWeight: 600}}>{user.Username}</span>
            <SecondaryButton caption='Logout' onClick={onLogout} style={{width: 100, float: 'right', marginLeft: 20, marginRight: 40, marginTop: 'auto', marginBottom: 'auto'}} icon={faSignOut}></SecondaryButton>
        </WhiteNavbar>
        <WhiteSidebar>
            <LogoDiv>
                <Logo style={{marginLeft: 20, marginTop: 'auto', marginBottom: 'auto'}} />
            </LogoDiv>
            <SideBarItem onClick={onSelectCompanies} selected={isOnCompanyPage}>
                <FontAwesomeIcon icon={faBriefcase} style={{marginLeft: 0, width: 14, height: 14}}></FontAwesomeIcon>
                <SideBarItemText>Companies</SideBarItemText>
            </SideBarItem>
            {user.Type && <SideBarItem onClick={onSelectUsers} selected={isOnUserPage}>
                <FontAwesomeIcon icon={faUserFriends} style={{marginLeft:0, width: 15, height: 15}}></FontAwesomeIcon>
                <SideBarItemText>Users</SideBarItemText>
            </SideBarItem>}
        </WhiteSidebar>
        <PageContent>
            <Routes>
                {user.Type && <Route path="/users" element={<Userspage {...props}/>}/>}
                <Route path="/companies/:routeCompanyID/properties" element={<CompaniesPage {...props}/>}/>
                <Route path="/companies/:routeCompanyID/properties/:routePropertyID/areas" element={<CompaniesPage {...props}/>}/>
                <Route path="/companies/:routeCompanyID/properties/:routePropertyID/areas/:routeAreaID/editArea" element={<CompaniesPage {...props}/>}/>
                <Route path="/companies/:routeCompanyID/properties/:routePropertyID/areas/addArea" element={<CompaniesPage {...props}/>}/>
                <Route path="/companies/*" element={<CompaniesPage {...props}/>}/>
            </Routes>
        </PageContent>
        <NotificationContainer />
    </StyledHomepage>
}

export default Homepage;