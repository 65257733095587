import styled from 'styled-components';
import Text from '../../SingleComponents/Text/text';
import TextArea from '../../SingleComponents/TextArea/textarea';

const StyledFormTextArea = styled.div`
`;

function FormTextArea(props) {

    const {label, value, placeholder, onChange, inputStyle, disabled, validated, onPressEnter, ...restProps} = props;

    return <StyledFormTextArea {...restProps}>
            <Text style={{fontSize: 13, marginBottom: 5}}>{props.label}</Text>
            <TextArea placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} onPressEnter={onPressEnter} disabled={disabled} validated={validated} style={inputStyle}></TextArea>
        </StyledFormTextArea>
}

export default FormTextArea;